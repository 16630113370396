// -------

import ActionType from './actionTypes'


// -------

const Actions = {

  /**
   * RESET
   */
  initialState: () => {
    return {
      type: ActionType.INITIAL_STATE,
    };
  },

  /**
   * GET
   */
  getFormData: (companyId, id) => {
    return {
      type: ActionType.GET_REQUEST,
      companyId,
      id
    };
  },
  getFormDataSuccess: response => {
    return {
      type: ActionType.GET_SUCCESS,
      payload: {
        ...response,
      },
    };
  },
  getFormDataFailed: errors => {
    return {
      type: ActionType.GET_FAILED,
      errors,
    };
  },

  // ----- FILTERS ----- 

    getFilters: (companyId) => {
      return {
        type: ActionType.FILTERS_REQUEST,
        companyId
      };
    },
    getFiltersSuccess: response => {
      return {
        type: ActionType.FILTERS_SUCCESS,
        payload: {
          ...response,
        },
      };
    },
    getFiltersFailed: errors => {
      return {
        type: ActionType.FILTERS_FAILED,
        errors,
      };
    },

  /**
   * SAVE
   */
  saveForm: (companyId, id, values) => {
    return {
      type: ActionType.SAVE_REQUEST,
      companyId, 
      id,
      payload: { ...values },
    };
  },
  saveFormSuccess: response => {
    return {
      type: ActionType.SAVE_SUCCESS,
      payload: {
        ...response,
      },
    };
  },
  saveFormFailed: errors => {
    return {
      type: ActionType.SAVE_FAILED,
      errors,
    };
  },


};

export default Actions;
