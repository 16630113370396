// -------

import {
  SAVE_PASSWORD_REQUEST,
  SAVE_PASSWORD_SUCCESS,
  SAVE_PASSWORD_FAILED,
} from './actionTypes'

// -------

const Actions = {
  SAVE_PASSWORD_REQUEST,
  SAVE_PASSWORD_SUCCESS,
  SAVE_PASSWORD_FAILED,

  /**
   * POST
   */
  savePassword: values => {
    return {
      type: SAVE_PASSWORD_REQUEST,
      payload: { ...values },
    };
  },
  savePasswordSuccess: response => {
    return {
      type: SAVE_PASSWORD_SUCCESS,
      payload: {
        ...response
      },
    };
  },
  savePasswordFailed: errors => {
    return {
      type: SAVE_PASSWORD_FAILED,
      errors,
    };
  },

};

export default Actions;
