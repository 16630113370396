import { produce } from 'immer';

// -------

const ProduceReducers = (state, action, ActionType) => {

  const produce_state = {
    collections: {},
    requesting: true,
  
    isDeleted: false,
    deleting: false,

    ...state
  }

  return produce(produce_state, draft => {
    
    switch (action.type) {

      // LIST
      case ActionType.LIST_REQUEST: {
        draft.collections = {};
        draft.requesting = true;
        break;
      }
      case ActionType.LIST_SUCCESS: {
        const { payload } = action;
        draft.collections = payload;
        draft.requesting = false;
        break;
      }
      case ActionType.LIST_FAILED: {
        draft.errors = action.errors;
        draft.requesting = false;
        break;
      }

      // DELETE
      case ActionType.DELETE_REQUEST: {
        draft.isDeleted = false;
        draft.deleting = true;
        break;
      }
      case ActionType.DELETE_SUCCESS: {
        draft.isDeleted = true;
        draft.deleting = false;
        break;
      }
      case ActionType.DELETE_FAILED: {
        draft.errors = action.errors;
        draft.deleting  = false;
        draft.isDeleted = false;
        break;
      }

      default:
        return state;
    }
  });
};

export default ProduceReducers
