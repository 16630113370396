import React from "react";
import { Spinner } from 'react-bootstrap';

// -------

import StyleWrapper from "./styles";

// -------

const PageLoader = ({ blocker }) => {
    
  return (
    <StyleWrapper>
      { blocker && <div className="page-loader-blocker"></div> } 
      <div className="page-loader-container">
        <Spinner variant="info" animation="border" /> 
        <span className="sr-only">Loading...</span>
      </div>
    </StyleWrapper>
  );
};

export default PageLoader
