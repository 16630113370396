// Admin
import { Routes as Dashboard } from 'modules/Admin/Dashboard';
import { Routes as Accounts } from 'modules/Admin/Accounts';
import { Routes as Clients } from 'modules/Admin/Clients';
import { Routes as Profile } from 'modules/Admin/Profile';

import { Routes as Squil } from 'modules/Admin/Squil';
import { Routes as Settings } from 'modules/Admin/Settings';
import { Routes as Reports } from 'modules/Admin/Reports';

// Public
import { PublicRoutes } from 'modules/Public';

// -------

const routes = {
  dashboard: { ...Dashboard },
  squil: { ...Squil },
  settings: { ...Settings },
  accounts : { ...Accounts },
  clients : { ...Clients },
  profile  : { ...Profile },
  reports  : { ...Reports },
  
  // Public Routes
  public: { ...PublicRoutes },
}
console.log('ROOT: Routes', routes)

export default routes;
