const ActionTypes = {
  LIST_REQUEST: '@admin.settings.warehouse-location.list/REQUEST/list',
  LIST_SUCCESS: '@admin.settings.warehouse-location.list/SUCCESS/list',
  LIST_FAILED: '@admin.settings.warehouse-location.list/FAILED/list',

  FILTERS_REQUEST: '@admin.settings.warehouse-location.list/REQUEST/filters',
  FILTERS_SUCCESS: '@admin.settings.warehouse-location.list/SUCCESS/filters',
  FILTERS_FAILED: '@admin.settings.warehouse-location.list/FAILED/filters',

  DELETE_REQUEST: '@admin.settings.warehouse-location.list/REQUEST/delete',
  DELETE_SUCCESS: '@admin.settings.warehouse-location.list/SUCCESS/delete',
  DELETE_FAILED: '@admin.settings.warehouse-location.list/FAILED/delete',
}

export default ActionTypes