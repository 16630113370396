
const ActionTypes = {
  LIST_REQUEST: '@admin.clients.department.list/REQUEST/index',
  LIST_SUCCESS: '@admin.clients.department.list/SUCCESS/index',
  LIST_FAILED: '@admin.clients.department.list/FAILED/index',

  DELETE_REQUEST: '@admin.clients.department.list/REQUEST/delete',
  DELETE_SUCCESS: '@admin.clients.department.list/SUCCESS/delete',
  DELETE_FAILED: '@admin.clients.department.list/FAILED/delete',
}

export default ActionTypes