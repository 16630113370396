
const Actions = (ActionType) => {

  return {
    
    /**
    * RESET
    */
    initialState: () => {
      return {
        type: ActionType.INITIAL_STATE,
      };
    },

    /**
    * GET
    */
    getFormData: (id, params) => {
      return {
        type: ActionType.REQUEST,
        id,
        params
      };
    },
    getFormDataReset: () => {
      return {
        type: ActionType.RESET
      };
    },
    getFormDataSuccess: response => {
      return {
        type: ActionType.SUCCESS,
        payload: {
          ...response,
        },
      };
    },
    getFormDataFailed: errors => {
      return {
        type: ActionType.FAILED,
        errors,
      };
    },


    /**
    * SAVE
    */
    saveForm: (id, values, params) => {
      return {
        type: ActionType.SAVE_REQUEST,
        id,
        payload: { ...values },
        params
      };
    },
    saveFormSuccess: response => {
      return {
        type: ActionType.SAVE_SUCCESS,
        payload: {
          ...response,
        },
      };
    },
    saveFormFailed: errors => {
      return {
        type: ActionType.SAVE_FAILED,
        errors,
      };
    },

  }
}

export default Actions
