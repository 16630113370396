import { lazy } from 'react'

// -------

import { routes } from 'config/app'
import { Rules } from 'config/permissions'

// -------

const PageInvoice     = lazy(() => import('./Invoice/List'))
const PageInvoiceForm = lazy(() => import('./Invoice/Form'))

const PageReport     = lazy(() => import('./Report/List'))
const PageReportForm = lazy(() => import('./Report/Form'))

// -------

const route = routes.backend + '/reports'

// -------
export const Api = {
  report: {
    index: '/reports/:type',
    form: '/reports/:type/create',
    formFilters: '/reports/:type/form-filters',
    findFilter: '/reports/:type/find-filters'
  },

  excel: {
    invoice: '/report/excel/invoice/:identifier/billing',
    report: '/report/excel/:identifier',
  },
}

// -------

export const Routes = {
  invoice: {
    index: `${route}/invoice`,
    form: `${route}/invoice/:id/form`,
  },
  report: {
    index: `${route}/report`,
    form: `${route}/report/:id/form`,
  },
}

export const Components = [
  { path: Routes.invoice.index, component: PageInvoice,     permission: Rules.page.reports.invoice },
  { path: Routes.invoice.form,  component: PageInvoiceForm, permission: Rules.page.reports.invoice },
  
  { path: Routes.report.index, component: PageReport,     permission: Rules.page.reports.report },
  { path: Routes.report.form,  component: PageReportForm, permission: Rules.page.reports.report },
]
