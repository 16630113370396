import { persistReducer } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'cookies-js';

// -------

const persistedReducer = reducers => {
  const key = process.env.REACT_APP_CODE || 'rynelle';
  const whitelist = ['auth', 'user', 'router'];
  const blacklist = [];

  return persistReducer(
    {
      storage: new CookieStorage(Cookies),
      key,
      whitelist,
      blacklist,
    },
    reducers
  );
};

export default persistedReducer;
