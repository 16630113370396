import { produce } from 'immer';
import { toast } from 'react-toastify';

// -------


import Actions from './actions';

// -------
const {
  SAVE_PASSWORD_REQUEST,
  SAVE_PASSWORD_SUCCESS,
  SAVE_PASSWORD_FAILED,
} = Actions

// -------

const INITIAL_STATE = {
  errors: {},
  submitting: false,
};

// -------

const ProduceReducers = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    
    switch (action.type) {

      // SAVE
      case SAVE_PASSWORD_REQUEST: {
        draft.errors = {};
        draft.submitting = true;
        break;
      }
      case SAVE_PASSWORD_SUCCESS: {
        toast.success('Password successfully updated')

        draft.submitting = false;
        break;
      }
      case SAVE_PASSWORD_FAILED: {
        draft.errors = action.errors;
        draft.submitting = false;
        break;
      }

      default:
        return state;
    }
  });
};

export default {
  AdminProfileSecurityReducers: ProduceReducers,
};
