import { takeLatest, put, call } from 'redux-saga/effects';

import RouteConst from 'app/routes'

// -------

import axios from 'app/services/http/axios';
import { history } from 'app/redux';

// -------

import Actions from './actions'

import ActionType from './actionTypes'
import { routePath } from 'app/services/helpers';

import { PublicApi } from 'modules/Public'

// -------

function* verify({ identifier }) {
  const { verifySuccess, verifyFailed } = Actions

  try {
    const route = routePath(PublicApi.resetPasswordVerify, { identifier });
    const response = yield call(axios.get, route);
    yield put(verifySuccess(response));

  } catch (e) {
    return yield put(verifyFailed(e));
  }
}


function* submit({ userId, payload }) {
  const { submitSuccess, submitFailed } = Actions

  try {
    const route = routePath(PublicApi.resetPassword, {id: userId});
    const response = yield call(axios.post, route, { ...payload });

    yield put(submitSuccess(response));
  } catch (e) {
    return yield put(submitFailed(e));
  }
}

// -------

const watchEffects = [
  takeLatest(ActionType.VERIFY_REQUEST, verify),
  takeLatest(ActionType.SUBMIT_REQUEST, submit)
]

export default watchEffects
