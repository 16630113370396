import { takeLatest, put, call } from 'redux-saga/effects';

import RouteConst from 'app/routes'

// -------

import axios from 'app/services/http/axios';
import { history } from 'app/redux';

// -------

import Actions from './actions'

import ActionType from './actionTypes'
import { routePath } from 'app/services/helpers';

import { PublicApi } from 'modules/Public'

// -------

function* verify({ identifier }) {
  const { verifySuccess, verifyFailed } = Actions

  try {
    const route = routePath(PublicApi.clientVerify, { identifier });
    const response = yield call(axios.get, route);
    yield put(verifySuccess(response));

  } catch (e) {
    return yield put(verifyFailed(e));
  }
}

function* save({ companyId, payload }) {

  const { 
    saveFormSuccess, 
    saveFormFailed
  } = Actions

  try {
    const route = routePath(PublicApi.clientSave, { companyId })

    const response = yield call(axios.post, route, { ...payload });
    yield put(saveFormSuccess(response));

  } catch (e) {
    return yield put(saveFormFailed(e));
  }
}

// -------

const watchEffects = [
  takeLatest(ActionType.VERIFY_REQUEST, verify),
  takeLatest(ActionType.SAVE_REQUEST, save)
]

export default watchEffects
