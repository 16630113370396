// -------

import ActionTypes from './actionTypes'

// -------

const Actions = {

  /**
   * GET
   */
  signIn: values => {
    return {
      type: ActionTypes.SIGNIN_REQUEST,
      payload: { ...values },
    };
  },
  signInSuccess: response => {
    return {
      type: ActionTypes.SIGNIN_SUCCESS,
      payload: {
        ...response,
        isLogged: true,
      },
    };
  },
  signInFailed: errors => {
    return {
      type: ActionTypes.SIGNIN_FAILED,
      errors,
    };
  },
};

export default Actions;
