
import TransactionTypesListEffects from './TransactionTypes/List/store/effects'
import TransactionTypesFormEffects from './TransactionTypes/Form/store/effects'

import UnitsListEffects from './Units/List/store/effects'
import UnitsFormEffects from './Units/Form/store/effects'

import PersonsListEffects from './Persons/List/store/effects'
import PersonsFormEffects from './Persons/Form/store/effects'

import WarehouseLocationListEffects from './WarehouseLocation/List/store/effects'
import WarehouseLocationFormEffects from './WarehouseLocation/Form/store/effects'

import MigrationEffects from './Migration/Index/store/effects'

export default [
  ...TransactionTypesListEffects,
  ...TransactionTypesFormEffects,

  ...UnitsListEffects,
  ...UnitsFormEffects,

  ...PersonsListEffects,
  ...PersonsFormEffects,

  ...WarehouseLocationListEffects,
  ...WarehouseLocationFormEffects,
  
  ...MigrationEffects,
]
