

const ActionTypes = {
  INITIAL_STATE: '@admin.squil.masterlist.list/REQUEST/initial-state',

  LIST_REQUEST: '@admin.squil.masterlist.list/REQUEST/list',
  LIST_SUCCESS: '@admin.squil.masterlist.list/SUCCESS/list',
  LIST_FAILED: '@admin.squil.masterlist.list/FAILED/list',

  FILTERS_REQUEST: '@admin.squil.masterlist.list/REQUEST/filters',
  FILTERS_SUCCESS: '@admin.squil.masterlist.list/SUCCESS/filters',
  FILTERS_FAILED: '@admin.squil.masterlist.list/FAILED/filters',

}

export default ActionTypes