

const ActionTypes = {
  INITIAL_STATE: '@admin.settings.unit.form/REQUEST/initial-state',

  REQUEST: '@admin.settings.unit.form/REQUEST/get',
  SUCCESS: '@admin.settings.unit.form/SUCCESS/get',
  FAILED: '@admin.settings.unit.form/FAILED/get',

  SAVE_REQUEST: '@admin.settings.unit.form/REQUEST/save',
  SAVE_SUCCESS: '@admin.settings.unit.form/SUCCESS/save',
  SAVE_FAILED: '@admin.settings.unit.form/FAILED/save'
}

export default ActionTypes