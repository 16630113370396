

const ActionTypes = {
  INITIAL_STATE: '@admin.squil.jobOrder.form/REQUEST/initial-state',

  GET_REQUEST: '@admin.squil.jobOrder.form/REQUEST/get',
  GET_SUCCESS: '@admin.squil.jobOrder.form/SUCCESS/get',
  GET_FAILED: '@admin.squil.jobOrder.form/FAILED/get',

  FILTERS_REQUEST: '@admin.squil.jobOrder.form/REQUEST/filters',
  FILTERS_SUCCESS: '@admin.squil.jobOrder.form/SUCCESS/filters',
  FILTERS_FAILED: '@admin.squil.jobOrder.form/FAILED/filters',

  SAVE_REQUEST: '@admin.squil.jobOrder.form/REQUEST/save',
  SAVE_SUCCESS: '@admin.squil.jobOrder.form/SUCCESS/save',
  SAVE_FAILED: '@admin.squil.jobOrder.form/FAILED/save'
}

export default ActionTypes