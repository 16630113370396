
import CompanyListReducers from './Admin/CompanyList/store/reducers'
import CompanyFormReducers from './Admin/CompanyForm/store/reducers'

import DepartmentListReducers from './Admin/DepartmentList/store/reducers'
import DepartmentFormReducers from './Admin/DepartmentForm/store/reducers'

import UserListReducers from './Admin/UserList/store/reducers'
import UserFormReducers from './Admin/UserForm/store/reducers'

import RateListReducers from './Admin/RateList/store/reducers'
import RateFormReducers from './Admin/RateForm/store/reducers'

export default {
  ...CompanyListReducers,
  ...CompanyFormReducers,

  ...DepartmentListReducers,
  ...DepartmentFormReducers,

  ...UserListReducers,
  ...UserFormReducers,
  
  ...RateListReducers,
  ...RateFormReducers,
}
