
const ActionTypes = {
  LIST_REQUEST: '@admin.squil.transaction.list/REQUEST/list',
  LIST_SUCCESS: '@admin.squil.transaction.list/SUCCESS/list',
  LIST_FAILED: '@admin.squil.transaction.list/FAILED/list',
  
  PDF_DOWNLOAD_REQUEST: '@admin.squil.transaction.list/REQUEST/pdf.download',

  DELETE_REQUEST: '@admin.squil.transaction.list/REQUEST/delete',
  DELETE_SUCCESS: '@admin.squil.transaction.list/SUCCESS/delete',
  DELETE_FAILED: '@admin.squil.transaction.list/FAILED/delete',
}

export default ActionTypes