import { produce } from 'immer';
import { toast } from 'react-toastify';

// -------

import ActionType from './actionTypes';

// -------

const INITIAL_STATE = {
  type: null,
  data: {},
  boxes: {},
  requesting: null,
  submitting: null,
};

// -------

const ProduceReducers = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    
    switch (action.type) {

      // RESET / INITIAL_STATE
      case ActionType.INITIAL_STATE: {
        draft.submitting = null;
        draft.boxes      = {};
        break;
      }

      case ActionType.GET_REQUEST: {
        draft.submitting = null;
        draft.requesting = true
        draft.data = {}
        break
      }
      case ActionType.GET_SUCCESS: {
        const { payload } = action
        draft.data = payload
        draft.requesting = false
        break
      }
      case ActionType.GET_FAILED: {
        draft.requesting = false
        setTimeout(() => { window.location.href = '/signout' }, 1000)
        break
      }

      // SAVE
      case ActionType.SAVE_REQUEST: {
        draft.errors = {};
        draft.submitting = true;
        break;
      }
      case ActionType.SAVE_SUCCESS: {
        toast.success('The boxes were successfully received')
        draft.submitting = false;
        break;
      }
      case ActionType.SAVE_FAILED: {
        draft.errors = action.errors;
        draft.submitting = null;
        break;
      }

      default: return state
    }
  });
}

const Reducers = {
  AdminSquilTransactionReceiveReducers: ProduceReducers,
}

export default Reducers
