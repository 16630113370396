// -------

import ActionType from './actionTypes'


// -------

const Actions = {

  /**
   * RESET
   */
  initialState: () => {
    return {
      type: ActionType.INITIAL_STATE,
    };
  },

  // ----- LIST ----- 

    getList: (pageNumber, filters) => {
      return {
        type: ActionType.LIST_REQUEST,
        pageNumber,
        filters
      };
    },
    getListSuccess: response => {
      return {
        type: ActionType.LIST_SUCCESS,
        payload: {
          ...response,
        },
      };
    },
    getListFailed: errors => {
      return {
        type: ActionType.LIST_FAILED,
        errors,
      };
    },

  // ----- FILTERS ----- 

    getFilters: () => {
      return {
        type: ActionType.FILTERS_REQUEST
      };
    },
    getFiltersSuccess: response => {
      return {
        type: ActionType.FILTERS_SUCCESS,
        payload: {
          ...response,
        },
      };
    },
    getFiltersFailed: errors => {
      return {
        type: ActionType.FILTERS_FAILED,
        errors,
      };
    },

};

export default Actions;
