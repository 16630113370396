import { produce } from 'immer';
import { toast } from 'react-toastify';

const ProduceReducers = (state, action, ActionType, saveMessage) => {

  const produce_state = {
    formdata: {},
    errors: {},
  
    requesting: null,
    submitting: null,

    ...state
  }

  return produce(produce_state, draft => {
    
    switch (action.type) {

      // RESET / INITIAL_STATE
      case ActionType.INITIAL_STATE: {
        draft.requesting = null;
        draft.submitting = null;
        draft.errors     = {};
        draft.formdata   = {};
        break;
      }

      // GET
      case ActionType.REQUEST: {
        draft.requesting = true;
        draft.formdata   = {};
        break;
      }
      case ActionType.SUCCESS: {
        const { payload } = action;
        draft.formdata    = payload;
        draft.requesting  = false;
        break;
      }
      case ActionType.FAILED: {
        draft.errors     = action.errors;
        draft.requesting = false;
        break;
      }

      // SAVE
      case ActionType.SAVE_REQUEST: {
        draft.errors     = {};
        draft.submitting = true;
        break;
      }
      case ActionType.SAVE_SUCCESS: {
        if ( saveMessage ) toast.success(saveMessage)
        draft.submitting = false;
        break;
      }
      case ActionType.SAVE_FAILED: {
        draft.errors     = action.errors;
        draft.submitting = null;
        break;
      }

      default: return state
    }
  })
}

export default ProduceReducers
