import createSagaMiddleware from 'redux-saga';

// -------

const monitor =
    // eslint-disable-next-line no-undef
  process.env.NODE_ENV === 'development' && console.tron
    ? console.tron.createSagaMonitor()
    : null;

export default createSagaMiddleware({ monitor });
