import ActionType from './actionTypes'

// -------

const Actions = {

  // ----- LIST ----- 

    getList: (pageNumber, filters) => {
      return {
        type: ActionType.LIST_REQUEST,
        pageNumber,
        filters
      };
    },
    getListSuccess: response => {
      return {
        type: ActionType.LIST_SUCCESS,
        payload: {
          ...response,
        },
      };
    },
    getListFailed: errors => {
      return {
        type: ActionType.LIST_FAILED,
        errors,
      };
    },


  // ----- DELETE ----- 
    toDelete: (id) => {
      return {
        type: ActionType.DELETE_REQUEST,
        id
      };
    },
    toDeleteSuccess: () => {
      return {
        type: ActionType.DELETE_SUCCESS
      };
    },
    toDeleteFailed: errors => {
      return {
        type: ActionType.DELETE_FAILED,
        errors,
      };
    },

};

export default Actions;
