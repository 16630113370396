import { lazy } from 'react'

// -------

import { routes } from 'config/app'
import { Rules } from 'config/permissions'

// -------

const ListPage = lazy(() => import('./List'))
const FormPage = lazy(() => import('./Form'))

// -------

const route = routes.backend + '/accounts'

// -------

export const Api = {
  accounts    : '/accounts',
  account     : '/accounts/:id',
  formFilters : '/accounts/form-filters',
}

export const Routes = {
  index: route,
  form: `${route}/:id/user/:username`
}

export const Components = [
  { path: Routes.index, component: ListPage, permission: Rules.page.accounts.index },
  { path: Routes.form,  component: FormPage, permission: Rules.page.accounts.index }
]
