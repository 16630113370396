import { takeLatest, put, call } from 'redux-saga/effects';

// -------

import axios from 'app/services/http/axios';
import Actions from './actions';

import { Api } from 'modules/Admin/Profile'

function* save({ payload }) {

  const { 
    savePasswordSuccess, 
    savePasswordFailed
  } = Actions

  try {
    const route = Api.password;
    const response = yield call(axios.post, route, { ...payload });
    yield put(savePasswordSuccess(response));

  } catch (e) {
    return yield put(savePasswordFailed(e));
  }
}

// -------
 
const { 
  SAVE_PASSWORD_REQUEST,
} = Actions

const watchEffects = [
  takeLatest(SAVE_PASSWORD_REQUEST, save),
]

export default watchEffects
