import { takeLatest, put } from 'redux-saga/effects';

// -------

import { history } from 'app/redux';

// -------

import {
  SIGNOUT_REQUEST
} from './actionTypes';
import Actions from './actions';

import RouteConst from 'app/routes'

// -------

function* signOut() {

  // NEED TO HAVE A LOGOUT REQUEST TO THE BACKEND
  // *************************************
  yield put(Actions.signOutSuccess());
  return history.push(RouteConst.public.signin);
}

// -------

const watchEffects = [
  takeLatest(SIGNOUT_REQUEST, signOut)
]

export default watchEffects
