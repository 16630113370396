import { takeLatest, put, call } from 'redux-saga/effects';

import RouteConst from 'app/routes'

// -------

import axios from 'app/services/http/axios';
import { history } from 'app/redux';

// -------

import Actions from './actions';
import ActionTypes from './actionTypes'

import { PublicApi } from 'modules/Public'

// -------

function* submit({ payload }) {
  const { submitSuccess, submitFailed } = Actions

  try {
    const route = PublicApi.forgotPassword;
    const response = yield call(axios.post, route, { ...payload });

    yield put(submitSuccess(response));
  } catch (e) {
    return yield put(submitFailed(e));
  }
}

// -------

const watchEffects = [
  takeLatest(ActionTypes.SUBMIT_REQUEST, submit)
]
export default watchEffects
