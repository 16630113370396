import styled from 'styled-components';

export default styled.div`
  .page-loader-blocker {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f3f6f9;
    z-index: 1011;
  }
  .page-loader-container {
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    width: 92px;
    text-align: center;
    height: 60px;
    margin-top: -60px;
    z-index: 1012;
  }
`;