// -------

import ActionType from './actionTypes'


// -------

const Actions = {

  /**
   * RESET
   */
  initialState: () => {
    return {
      type: ActionType.INITIAL_STATE,
    };
  },

  /**
   * GET
   */
  getData: (id) => {
    return {
      type: ActionType.GET_REQUEST,
      id
    };
  },
  getDataSuccess: response => {
    return {
      type: ActionType.GET_SUCCESS,
      payload: {
        ...response,
      },
    };
  },
  getDataFailed: errors => {
    return {
      type: ActionType.GET_FAILED,
      errors,
    };
  },

  /**
   * SAVE
   */
  saveStatus: (id, values) => {
    return {
      type: ActionType.SAVE_STATUS_REQUEST,
      id,
      payload: { ...values },
    };
  },
  saveStatusSuccess: response => {
    return {
      type: ActionType.SAVE_STATUS_SUCCESS,
      payload: {
        ...response,
      },
    };
  },
  saveStatusFailed: errors => {
    return {
      type: ActionType.SAVE_STATUS_FAILED,
      errors,
    };
  },

};

export default Actions;
