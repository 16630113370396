
import RouteConst from 'app/routes'
import { Rules } from './permissions'

const menus = [
  {
    title : "App",
    permission: Rules.menu.app
  },
  {
    title:      "Dashboard",
    to:         RouteConst.dashboard.index,
    icon:       "uil-home-alt",
    permission: Rules.menu.dashboard
  },
  {
    title : "SQUIL",
    permission: Rules.menu.squil
  },
    // ------- Admin -------
    {
      title:      "Masterlist",
      to:         RouteConst.squil.masterlist.index,
      icon:       "uil-list-ul",
      permission: Rules.menu.masterlist
    },
    {
      title:      "Job Orders",
      to:         RouteConst.squil.jobOrder.index,
      icon:       "uil-coins",
      permission: Rules.menu.jobOrder
    },
    {
      title:      "Barcode Scan",
      to:         RouteConst.squil.barcodeScan,
      icon:       "fas fa-barcode",
      permission: Rules.menu.barcodeScan
    },

  {
    title : "TRANSACTIONS",
    permission: Rules.menu.transactions
  },
    // ------- Clients -------
    {
      title:      "Pick-up",
      to:         RouteConst.squil.transaction.pickup.index,
      icon:       "uil-bring-bottom",
      permission: Rules.menu.transaction.pickup
    },
    {
      title:      "Retrieval",
      to:         RouteConst.squil.transaction.retrieval.index,
      icon:       "uil-bring-front",
      permission: Rules.menu.transaction.retrieval
    },
    {
      title:      "Return",
      to:         RouteConst.squil.transaction.repickup.index,
      icon:       "uil-history-alt",
      permission: Rules.menu.transaction.repickup
    },
    {
      title:      "Access on Site",
      to:         RouteConst.squil.transaction.access.index,
      icon:       "uil-layers",
      permission: Rules.menu.transaction.access
    },
    {
      title:      "Destruction",
      to:         RouteConst.squil.transaction.destruction.index,
      icon:       "uil-layer-group-slash",
      permission: Rules.menu.transaction.destruction
    },
  {
    title : "REPORTS",
    permission: Rules.menu.reports
  },
    {
      title:      "Billing Invoices",
      to:         RouteConst.reports.invoice.index,
      icon:       "uil-invoice",
      permission: Rules.menu.invoice
    },
    {
      title:      "Reports",
      to:         RouteConst.reports.report.index,
      icon:       "uil-file-copy-alt",
      permission: Rules.menu.reports
    },
  {
    title : "SETTINGS",
    permission: Rules.menu.settings
  },

    // ------- Admin -------
      {
        title:      "Accounts",
        to:         RouteConst.accounts.index,
        icon:       "uil-users-alt",
        permission: Rules.menu.accounts
      },
      {
        title:      "Clients",
        to:         RouteConst.clients.company.index,
        icon:       "uil-building",
        permission: Rules.menu.clients
      },
      {
        title:      "Drivers",
        to:         RouteConst.settings.persons.drivers.index,
        icon:       "uil-user-plus",
        permission: Rules.menu.persons
      },
      {
        title:      "Helpers",
        to:         RouteConst.settings.persons.helpers.index,
        icon:       "uil-user-plus",
        permission: Rules.menu.persons
      },
      {
        title:      "Warehouse",
        to:         RouteConst.settings.warehouseLocation.index,
        icon:       "uil-university",
        permission: Rules.menu.warehouseLocation
      },
      {
        title:      "Migrations",
        to:         RouteConst.settings.migration.index,
        icon:       "uil-refresh",
        permission: Rules.menu.migration
      },
      {
        title:      "Settings",
        to:         RouteConst.settings.index,
        icon:       "uil-cog",
        permission: Rules.menu.pageSettings,
      },

    // ------- Clients -------
      {
        title:      "Departments",
        to:         RouteConst.clients.client.department.index,
        icon:       "uil-layer-group",
        permission: Rules.menu.departments
      }
  
]

export default menus
