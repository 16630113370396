import { takeLatest, put, call } from 'redux-saga/effects';
import { routePath } from 'app/services/helpers'
// -------

import axios from 'app/services/http/axios';

// -------

import Actions from './actions';
import ActionType from './actionTypes';

import { Api } from 'modules/Admin/Squil'

// -------

function* get({barcode}) {
  const { 
    getDataSuccess, 
    getDataFailed 
  } = Actions

  try {
    const route = routePath(Api.barcode.scan, { barcode })

    const response = yield call(axios.get, route);
    yield put(getDataSuccess(response));

  } catch (e) {
    return yield put(getDataFailed(e));
  }
}
const watchEffects = [
  takeLatest(ActionType.GET_REQUEST, get),
]

export default watchEffects
