import { takeLatest, put, call } from 'redux-saga/effects';
import { routePath } from 'app/services/helpers'
// -------

import axios from 'app/services/http/axios';

// -------

import Actions from './actions';
import ActionType from './actionTypes';

import { objectToUrl } from 'app/services/helpers'

import { Api } from 'modules/Admin/Squil'

// -------

function* getList({pageNumber, filters}) {
  const { 
    getListSuccess, 
    getListFailed 
  } = Actions

  try {
    
    let route = Api.masterlist.list;
    route += objectToUrl({
      ...filters,
      page: pageNumber
    }, true)

    const response = yield call(axios.get, route);
    yield put(getListSuccess(response));

  } catch (e) {
    return yield put(getListFailed(e));
  }
}

function* getFilters() {
  const { 
    getFiltersSuccess, 
    getFiltersFailed 
  } = Actions
  try {
    const route = Api.masterlist.filters;
    const response = yield call(axios.get, route);
    yield put(getFiltersSuccess(response));

  } catch (e) {
    return yield put(getFiltersFailed(e));
  }
}

const watchEffects = [
  takeLatest(ActionType.LIST_REQUEST, getList),
  takeLatest(ActionType.FILTERS_REQUEST, getFilters),
]

export default watchEffects
