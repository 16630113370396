import CommonFormReducers from 'modules/Admin/Common/Form/store/reducers'

import ActionType from './actionTypes'

const ProduceReducers = (state = {}, action) => CommonFormReducers(state, action, ActionType)

const Reducers = {
  AdminSettingsPersonFormReducers: ProduceReducers
}

export default Reducers