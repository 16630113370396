import React, { Fragment } from 'react';
import { Navigate } from 'react-router-dom';

// -------

import RouteConst from 'app/routes'

// -------

const Protected = ({ isLogged, children }) => {

  return (
    <Fragment>
      {isLogged ? (
        <Fragment>{children}</Fragment>
      ) : (
        <Navigate to={RouteConst.public.signin} />
      )}
    </Fragment>
  );
};
export default Protected;
