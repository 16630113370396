
const ActionTypes = {
  VERIFY_REQUEST: '@public.client.account/REQUEST/verify',
  VERIFY_SUCCESS: '@public.client.account/SUCCESS/verify',
  VERIFY_FAILED: '@public.client.account/FAILED/verify',

  SAVE_REQUEST: '@public.client.account/REQUEST/save',
  SAVE_SUCCESS: '@public.client.account/SUCCESS/save',
  SAVE_FAILED: '@public.client.account/FAILED/save'
}

export default ActionTypes