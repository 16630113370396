import { produce } from 'immer';
import { toast } from 'react-toastify';

// -------

import ActionType from './actionTypes';

// -------

const INITIAL_STATE = {
  data: null,
  requesting: null,
};

// -------

const ProduceReducers = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    
    switch (action.type) {

      // RESET / INITIAL_STATE
      case ActionType.INITIAL_STATE: {
        draft.requesting = null;
        draft.data       = {};
        break;
      }

      // GET
      case ActionType.GET_REQUEST: {
        draft.requesting = true;
        draft.data = {};
        break;
      }
      case ActionType.GET_SUCCESS: {
        const { payload } = action;
        draft.data = payload;
        draft.requesting = false;
        break;
      }
      case ActionType.GET_FAILED: {
        draft.errors = action.errors;
        draft.requesting = false;
        break;
      }

      default:
        return state
        break;
    }
  });
}

const Reducers = {
  AdminSquilBarcodeScanViewReducers: ProduceReducers,
}

export default Reducers
