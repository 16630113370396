import { produce } from 'immer';
import { toast } from 'react-toastify';

// -------

import ActionType from './actionTypes';

// -------

const INITIAL_STATE = {
  collections: {},
  requesting: true,

  filters: {
    loaded: false
  },
};

// -------

const ProduceReducers = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    
    switch (action.type) {

      // RESET / INITIAL_STATE
      case ActionType.INITIAL_STATE: {
        draft.requesting = null;
        draft.submitting = null;
        draft.errors     = {};
        draft.formdata   = {};
        break;
      }

      // LIST
      case ActionType.LIST_REQUEST: {
        draft.collections = {};
        draft.requesting  = true;
        break;
      }
      case ActionType.LIST_SUCCESS: {
        const { payload } = action;
        draft.collections = payload;
        draft.requesting = false;
        break;
      }
      case ActionType.LIST_FAILED: {
        draft.errors = action.errors;
        draft.requesting = false;
        break;
      }

      // FILTERS
      case ActionType.FILTERS_REQUEST: {
        draft.requesting = true;
        break;
      }
      case ActionType.FILTERS_SUCCESS: {
        const { payload } = action;
        draft.filters = payload;
        draft.filters.loaded = true
        break;
      }
      case ActionType.FILTERS_FAILED: {
        draft.errors = action.errors;
        draft.requesting = false;
        break;
      }

      default:
        return state
        break;
    }
  });
}

const Reducers = {
  AdminSquilMasterListReducers: ProduceReducers,
}

export default Reducers
