
import CompanyListEffects from './Admin/CompanyList/store/effects'
import CompanyFormEffects from './Admin/CompanyForm/store/effects'

import DepartmentListEffects from './Admin/DepartmentList/store/effects'
import DepartmentFormEffects from './Admin/DepartmentForm/store/effects'

import UserListEffects from './Admin/UserList/store/effects'
import UserFormEffects from './Admin/UserForm/store/effects'

import RateListEffects from './Admin/RateList/store/effects'
import RateFormEffects from './Admin/RateForm/store/effects'

export default [
  ...CompanyListEffects,
  ...CompanyFormEffects,
  
  ...DepartmentListEffects,
  ...DepartmentFormEffects,

  ...UserListEffects,
  ...UserFormEffects,

  ...RateListEffects,
  ...RateFormEffects,
]
