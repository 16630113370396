/* eslint-disable no-console */
import axios from 'axios';
import { toast } from 'react-toastify';

// -------

import { store } from 'app/redux';

// -------

const BASE_ENV = process.env.REACT_APP_NODE_ENV;
const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_REQUEST = process.env.REACT_APP_BASE_REQUEST;
// const BASE_VERSION = process.env.REACT_APP_BASE_VERSION;
const HEADER_API = process.env.REACT_APP_HEADER_API;
axios.defaults.baseURL = `${BASE_URL}/${BASE_REQUEST}`;

axios.interceptors.request.use(function(config) {
  console.log('config.headers', config.headers)
  config.headers['Accept'] = 'application/json';
  config.headers['x-requested-env'] = BASE_ENV;
  config.headers['x-requested-token'] = HEADER_API;

  const { auth } = store.getState();
  if (auth.isLogged === true) {
    config.headers['Authorization'] = 'Bearer ' + auth.token;
  }
  return config;
});

axios.interceptors.response.use(
  response => {
    console.log('> RESPONSE(SUCCESS):', {...response})
    
    const payload = response.data || {};
    if ( payload instanceof Blob ) return Promise.resolve(payload)
    
    if (payload.success === false) {
      toast.error(Object.values(payload.errors).join("\n"), {
        autoClose: 3000,
      });
      // setTimeout(() => { window.location.href = '/signout' }, 2000)

      return Promise.reject(payload.errors) 
    } else {
      return Promise.resolve(payload.data)
    }
  },
  error => {
    console.log('> RESPONSE(ERROR):', {...error})
    
    const errors     = error.response || {};
    const error_data = errors.data || "You don't have permission to access this api.";

    switch (errors.status) {
      case 401:
        toast.error("Your session has expired", {
          autoClose: 3000,
        });
        // setTimeout(() => { window.location.href = '/signout' }, 2000)
        break;
      default:
        const error_message = errors.data.errors?.error || errors.data.message || errors.statusText
        toast.error(error_message, {
          autoClose: 5000,
        }); 
        // setTimeout(() => { window.location.href = '/admin/dashboard' }, 3000)
        break;
    }
    return Promise.reject(error_data);
  }
);

export default axios;
