import { takeLatest, put, call } from 'redux-saga/effects';

// -------

import axios from 'app/services/http/axios';
import Actions from './actions';
import ActionType from './actionTypes';
import { objectToUrl } from 'app/services/helpers'

import { Api } from 'modules/Admin/Settings'

// -------

function* getList({pageNumber, filters}) {
  const { getListSuccess, getListFailed } = Actions

  try {
    let route = Api.transactionTypes.index;
    route += objectToUrl({
      ...filters,
      page: pageNumber
    }, true)

    const response = yield call(axios.get, route);
    yield put(getListSuccess(response));

  } catch (e) {
    return yield put(getListFailed(e));
  }
}

function* toDelete({id}) {
  const { toDeleteSuccess, toDeleteFailed } = Actions
  
  try {
    let route = `${Api.transactionTypes.index}/${id}`;
    const response = yield call(axios.delete, route);
    yield put(toDeleteSuccess(response));
  } catch (e) {
    return yield put(toDeleteFailed(e));
  }
}

// -------
 
const watchEffects = [
  takeLatest(ActionType.LIST_REQUEST, getList),
  takeLatest(ActionType.DELETE_REQUEST, toDelete),
]

export default watchEffects
