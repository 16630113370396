import { lazy } from 'react'

// -------

import { routes } from 'config/app'
import { Rules } from 'config/permissions'


// ADMIN
  const CompanyListPage = lazy(() => import('./Admin/CompanyList'))
  const CompanyFormPage = lazy(() => import('./Admin/CompanyForm'))
  const DepartmentListPage = lazy(() => import('./Admin/DepartmentList'))
  const DepartmentFormPage = lazy(() => import('./Admin/DepartmentForm'))
  const UserListPage = lazy(() => import('./Admin/UserList'))
  const UserFormPage = lazy(() => import('./Admin/UserForm'))
  const RateListPage = lazy(() => import('./Admin/RateList'))
  const RateFormPage = lazy(() => import('./Admin/RateForm'))


// CLIENTS
  const ClientDepartmentListPage = lazy(() => import('./Client/DepartmentList'))
  const ClientDepartmentFormPage = lazy(() => import('./Client/DepartmentForm'))
  const ClientUserListPage = lazy(() => import('./Client/UserList'))
  const ClientUserFormPage = lazy(() => import('./Client/UserForm'))

// -------

export const Api = {
  companies       : '/clients/companies',
  company         : '/clients/companies/:id',
  departments     : '/clients/companies/:companyId/departments',
  department      : '/clients/companies/:companyId/departments/:id',
  users           : '/clients/companies/:companyId/users',
  user            : '/clients/companies/:companyId/users/:id',
  userFormFilters : '/clients/companies/:companyId/users/form-filters',

  rates           : '/squil/settings/company/:companyId/rates',
  rate            : '/squil/settings/company/:companyId/rates/:id',
  rateFormFilters : '/squil/settings/company/:companyId/rates/form-filters',
}


const route = routes.backend + '/clients'

export const Routes = {
    company: {
      index: `${route}/companies`,
      form:  `${route}/companies/:id/form`,
    },
    department: {
      index: `${route}/companies/:companyId/departments`,
      form:  `${route}/companies/:companyId/departments/:id/form`,
    },
    user: {
      index: `${route}/companies/:companyId/users`,
      form:  `${route}/companies/:companyId/users/:id/form`,
    },
    rate: {
      index: `${route}/companies/:companyId/rates`,
      form:  `${route}/companies/:companyId/rates/:id/form`,
    },

  // Clients
    client: {
      department: {
        index: `${route}/departments`,
        form:  `${route}/departments/:id/form`,
      },
      user: {
        index: `${route}/users`,
        form:  `${route}/users/:id/form`,
      }
    }
}


export const Components = [
  // Admin
  { path: Routes.company.index,    component: CompanyListPage,    permission: Rules.page.clients.admin },
  { path: Routes.company.form,     component: CompanyFormPage,    permission: Rules.page.clients.admin },
  { path: Routes.department.index, component: DepartmentListPage, permission: Rules.page.clients.admin },
  { path: Routes.department.form,  component: DepartmentFormPage, permission: Rules.page.clients.admin },
  { path: Routes.user.index,       component: UserListPage,       permission: Rules.page.clients.admin },
  { path: Routes.user.form,        component: UserFormPage,       permission: Rules.page.clients.admin },
  { path: Routes.rate.index,       component: RateListPage,       permission: Rules.page.clients.admin },
  { path: Routes.rate.form,        component: RateFormPage,       permission: Rules.page.clients.admin },

  // Clients
  { path: Routes.client.department.index, component: ClientDepartmentListPage, permission: Rules.page.clients.client },
  { path: Routes.client.department.form,  component: ClientDepartmentFormPage, permission: Rules.page.clients.client },
  // { path: Routes.client.user.index,       component: ClientUserListPage,       permission: Rules.page.clients.client },
  // { path: Routes.client.user.form,        component: ClientUserFormPage,       permission: Rules.page.clients.client },
]
