// layout
import Layout from 'app/layouts/store/reducer';

// Public
import PublicSignIn from 'modules/Public/SignIn/store/reducers';
import PublicClientAccount from 'modules/Public/ClientAccount/store/reducers';
import PublicForgotPassword from 'modules/Public/ForgotPassword/store/reducers';
import PublicResetPassword from 'modules/Public/ResetPassword/store/reducers';

// Admin
import AdminProfile from 'modules/Admin/Profile/reducers'
import AdminAccounts from 'modules/Admin/Accounts/reducers'
import AdminClients from 'modules/Admin/Clients/reducers'
import AdminSquil from 'modules/Admin/Squil/reducers'
import AdminSettings from 'modules/Admin/Settings/reducers'
import AdminReports from 'modules/Admin/Reports/reducers'

// -------

const reducers = {
  ...Layout,

  // Admin
    ...AdminProfile,
    ...AdminAccounts,
    ...AdminClients,
    ...AdminSquil,
    ...AdminSettings,
    ...AdminReports,

  // Public
    ...PublicSignIn,
    ...PublicClientAccount,
    ...PublicForgotPassword,
    ...PublicResetPassword,
};
console.log('ROOT:Reducers', reducers);

export default reducers;
