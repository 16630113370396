import { takeLatest, put, call } from 'redux-saga/effects';
import { routePath } from 'app/services/helpers'
// -------

import axios from 'app/services/http/axios';

// -------

import Actions from './actions';
import ActionType from './actionTypes';

import { Api } from 'modules/Admin/Squil'

// -------

function* get({id}) {
  const { 
    getDataSuccess, 
    getDataFailed 
  } = Actions

  try {
    let route = routePath(Api.jobOrder.details, { id })
        route += '?type=receive' 

    const response = yield call(axios.get, route);
    yield put(getDataSuccess(response));

  } catch (e) {
    return yield put(getDataFailed(e));
  }
}

function* save({ id, payload }) {

  const { 
    saveFormSuccess, 
    saveFormFailed
  } = Actions 

  try {

    const route = routePath(Api.jobOrder.receive, { id })
    const response = yield call(axios.post, route, { ...payload });
    yield put(saveFormSuccess(response));

  } catch (e) {
    return yield put(saveFormFailed(e));
  }
}

const watchEffects = [
  takeLatest(ActionType.GET_REQUEST, get),
  takeLatest(ActionType.SAVE_REQUEST, save),
]

export default watchEffects
