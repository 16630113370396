/* LAYOUT */
export const CHANGE_LAYOUT = "@layout/CHANGE_LAYOUT"
export const CHANGE_LAYOUT_WIDTH = "@layout/CHANGE_LAYOUT_WIDTH"
export const CHANGE_SIDEBAR_THEME = "@layout/CHANGE_SIDEBAR_THEME"
export const CHANGE_SIDEBAR_TYPE = "@layout/CHANGE_SIDEBAR_TYPE"

// topbar
export const CHANGE_TOPBAR_THEME = "@layout/CHANGE_TOPBAR_THEME"

// show sidebar
export const SHOW_SIDEBAR = "@layout/SHOW_SIDEBAR"
export const TOGGLE_LEFTMENU = "@layout/TOGGLE_LEFTMENU"

/* RIGHT SIDEBAR */
export const SHOW_RIGHT_SIDEBAR = "@layout/SHOW_RIGHT_SIDEBAR"

// Preloader
export const CHANGE_PRELOADER = "@layout/CHANGE_PRELOADER"
