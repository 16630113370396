import { takeLatest, put, call } from 'redux-saga/effects';

// -------

import axios from 'app/services/http/axios';
import Actions from './actions';
import ActionType from './actionTypes';
import { objectToUrl, routePath } from 'app/services/helpers'

import { Api } from 'modules/Admin/Squil'

// -------

function* getList({transactionType, pageNumber, filters}) {
  const { getListSuccess, getListFailed } = Actions

  try {
    let route = routePath(Api.transactions.list, {type: transactionType});
    route += objectToUrl({
      ...filters,
      page: pageNumber
    }, true)

    const response = yield call(axios.get, route);
    yield put(getListSuccess(response));

  } catch (e) {
    return yield put(getListFailed(e));
  }
}

function* pdfDownload({transactionType, identifier}) {

  try {
    let route = routePath(Api.transactions.pdf, {type: transactionType, identifier});

    // axios.defaults.responseType = 'blob'
    axios.get(route)
         .then((response) => {
          const blob = response.blob()

      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `FileName.pdf`,      );
      // Append888iiiiii to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);

      // create "a" HTML element with href to file & click
      // const link = document.createElement('a');
      // link.href = response;
      // link.setAttribute('download', 'file.pdf'); //or any other extension
      // link.setAttribute('target', '_blank'); //or any other extension
      // document.body.appendChild(link);
      // link.click();

      // document.body.removeChild(link);
      // URL.revokeObjectURL(response);
      
      // const a = document.createElement('a');
      // a.href = response;
      // a.target = '_blank';
      // a.download = `${transactionType}.pdf`;
      // document.body.appendChild(a);
      // a.click();
      // a.remove();

      }).catch((e) => {});
  } catch (e) {}

}

function* toDelete({transactionType, id}) {
  const { toDeleteSuccess, toDeleteFailed } = Actions
  
  try {
    let route = routePath(`${Api.transactions.list}/${id}`, {type: transactionType});
    const response = yield call(axios.delete, route);
    yield put(toDeleteSuccess(response));
  } catch (e) {
    return yield put(toDeleteFailed(e));
  }
}

// -------
 
const watchEffects = [
  takeLatest(ActionType.LIST_REQUEST, getList),
  takeLatest(ActionType.PDF_DOWNLOAD_REQUEST, pdfDownload),
  takeLatest(ActionType.DELETE_REQUEST, toDelete),
]

export default watchEffects
