import { all } from 'redux-saga/effects';

// -------

import effects from 'app/effects';

// -------

const RootEffects = function* rootEffects() {
  return yield all([...effects]);
};

export default RootEffects;
