import { lazy } from 'react'

// -------

import { routes } from 'config/app'
import { Rules } from 'config/permissions'
import { routePath } from 'app/services/helpers'

// -------


const PageTransactionTypes     = lazy(() => import('./TransactionTypes/List'))
const PageTransactionTypesForm = lazy(() => import('./TransactionTypes/Form'))

const PageUnits     = lazy(() => import('./Units/List'))
const PageUnitsForm = lazy(() => import('./Units/Form'))

const PageWarehouseLocation     = lazy(() => import('./WarehouseLocation/List'))
const PageWarehouseLocationForm = lazy(() => import('./WarehouseLocation/Form'))

const PageDrivers     = lazy(() => import('./Persons/List/drivers'))
const PageDriversForm = lazy(() => import('./Persons/Form/drivers'))
const PageHelpers     = lazy(() => import('./Persons/List/helpers'))
const PageHelpersForm = lazy(() => import('./Persons/Form/helpers'))

const PageMigrationIndex = lazy(() => import('./Migration/Index'))

// -------

const route = routes.backend + '/settings'

// -------

export const Api = {
  transactionTypes: {
    index: '/squil/settings/transaction-types',
    form: '/squil/settings/transaction-types/:id'
  },
  units: {
    index: '/squil/settings/units',
    form: '/squil/settings/units/:id'
  },
  persons: {
    index: '/squil/settings/persons/:type',
    form: '/squil/settings/persons/:type/:id'
  },
  warehouseLocation: {
    index: '/squil/settings/warehouse-locations',
    form: '/squil/settings/warehouse-locations/:id',
    filters: '/squil/settings/warehouse-locations/filters',
    formFilters: '/squil/settings/warehouse-locations/form-filters'
  },
  migration: {
    validate: '/squil/migration/validate',
    submit: '/squil/migration/submit',
  },
}

// -------

export const Routes = {
  index: `${route}/squil/transaction-types`,
  
  transactionTypes: {
    index: `${route}/squil/transaction-types`,
    form: `${route}/squil/transaction-types/:id/form`,
  },
  units: {
    index: `${route}/squil/units`,
    form: `${route}/squil/units/:id/form`,
  },

  persons: {
    drivers: {
      index: `${route}/squil/person/drivers`,
      form: `${route}/squil/person/drivers/:id/form`,
    },
    helpers: {
      index: `${route}/squil/person/helpers`,
      form: `${route}/squil/person/helpers/:id/form`,
    }
  },

  warehouseLocation: {
    index: `${route}/warehouse-locations`,
    form: `${route}/warehouse-locations/:id/form`,
  },

  migration: {
    index: `${route}/migration`,
  },
}

export const Components = [
  { path: Routes.persons.drivers.index, component: PageDrivers,     permission: Rules.page.settings.persons },
  { path: Routes.persons.drivers.form,  component: PageDriversForm, permission: Rules.page.settings.persons },
  { path: Routes.persons.helpers.index, component: PageHelpers,     permission: Rules.page.settings.persons },
  { path: Routes.persons.helpers.form,  component: PageHelpersForm, permission: Rules.page.settings.persons },

  { path: Routes.transactionTypes.index, component: PageTransactionTypes,     permission: Rules.page.settings.transactionTypes },
  { path: Routes.transactionTypes.form,  component: PageTransactionTypesForm, permission: Rules.page.settings.transactionTypes },

  { path: Routes.units.index, component: PageUnits,     permission: Rules.page.settings.units },
  { path: Routes.units.form,  component: PageUnitsForm, permission: Rules.page.settings.units },

  { path: Routes.warehouseLocation.index, component: PageWarehouseLocation,     permission: Rules.page.settings.warehouseLocation },
  { path: Routes.warehouseLocation.form,  component: PageWarehouseLocationForm, permission: Rules.page.settings.warehouseLocation },

  { path: Routes.migration.index,  component: PageMigrationIndex, permission: Rules.page.settings.migration },

]
