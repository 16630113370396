import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import { useLocation } from 'react-router-dom';

import { connect } from "react-redux"

import Actions from 'app/layouts/store/actions'

import Header from "./components/Header"
import Footer from "./components/Footer"
import Sidebar from "./components/Sidebar"
import { useTranslation } from 'react-i18next';

const AdminLayout = ({children}) => {
  const { t } = useTranslation();

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  

  useEffect(() => {

    window.scrollTo(0, 0)
    document.title = t('site.title')

    if (Actions.leftSideBarTheme) {
      Actions.changeSidebarTheme(Actions.leftSideBarTheme)
    }

    if (Actions.layoutWidth) {
      Actions.changeLayoutWidth(Actions.layoutWidth)
    }

    if (Actions.leftSideBarType) {
      Actions.changeSidebarType(Actions.leftSideBarType)
    }
    if (Actions.topbarTheme) {
      Actions.changeTopbarTheme(Actions.topbarTheme)
    }

  }, []);

  const toggleMenuCallback = () => {
    if (Actions.leftSideBarType === "default") {
      Actions.changeSidebarType("condensed", isMobile)
    } else if (Actions.leftSideBarType === "condensed") {
      Actions.changeSidebarType("default", isMobile)
    }
  }

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback} />
        <Sidebar
          theme={Actions.leftSideBarTheme}
          type={Actions.leftSideBarType}
          isMobile={isMobile}
        />
        <div className="main-content">{children}</div>
        {/* <Footer /> */}
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  return {
    ...state.Layout,
  }
}

export default connect(mapStatetoProps)(AdminLayout)
