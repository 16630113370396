
import InvoiceListEffects from './Invoice/List/store/effects'
import InvoiceFormEffects from './Invoice/Form/store/effects'

import ReportListEffects from './Report/List/store/effects'
import ReportFormEffects from './Report/Form/store/effects'

export default [
  ...InvoiceListEffects,
  ...InvoiceFormEffects,

  ...ReportListEffects,
  ...ReportFormEffects,
]
