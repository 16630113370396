
const ActionTypes = (module, actions = {}) => {
  return {

    INITIAL_STATE: `${module}.form/REQUEST/initial-state`,

    REQUEST: `${module}.form/REQUEST/get`,
    SUCCESS: `${module}.form/SUCCESS/get`,
    FAILED: `${module}.form/FAILED/get`,

    SAVE_REQUEST: `${module}.form/REQUEST/save`,
    SAVE_SUCCESS: `${module}.form/SUCCESS/save`,
    SAVE_FAILED: `${module}.form/FAILED/save`,

    ...actions,
  }
}

export default ActionTypes