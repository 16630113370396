import { lazy } from 'react'

// -------

import { routes } from 'config/app'

// -------

const DashboardPage = lazy(() => import('./Index'))

const route = routes.backend + '/dashboard'

// -------

export const Routes = {
  identifier: 'dashboard',

  index: route
}

export const Components = [
  { path: Routes.index, component: DashboardPage },
]
