

const ActionTypes = {
  INITIAL_STATE: '@admin.squil.jobOrder.view/REQUEST/initial-state',

  GET_REQUEST: '@admin.squil.jobOrder.view/REQUEST/get',
  GET_SUCCESS: '@admin.squil.jobOrder.view/SUCCESS/get',
  GET_FAILED: '@admin.squil.jobOrder.view/FAILED/get',

  SAVE_STATUS_REQUEST: '@admin.squil.jobOrder.view/REQUEST/save-status',
  SAVE_STATUS_SUCCESS: '@admin.squil.jobOrder.view/SUCCESS/save-status',
  SAVE_STATUS_FAILED: '@admin.squil.jobOrder.view/FAILED/save-status',
}

export default ActionTypes