import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
// import { withRouter , Link } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./partials/SidebarContent"

import logo from "assets/img/logo.png"
import logoName from "assets/img/logo-name.png"

const Sidebar = props => {
  
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box tmp-header">
          <Link to="/" className="logo">
            <span className="logo-sm">
              <img src={logo} alt="" height="42" />
            </span>
            <span className="logo-lg">
              <img src={logoName} alt="" height="56" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(Sidebar)