// -------

import ActionType from './actionTypes'

// -------

const Actions = {

  /**
   * VERIFY
   */
  verify: identifier => {
    return {
      type: ActionType.VERIFY_REQUEST,
      identifier,
    };
  },
  verifySuccess: response => {
    return {
      type: ActionType.VERIFY_SUCCESS,
      payload: { ...response },
    };
  },
  verifyFailed: errors => {
    return {
      type: ActionType.VERIFY_FAILED,
      errors,
    };
  },

  /**
   * SUBMIT
   */
  submit: (userId, values) => {
    return {
      type: ActionType.SUBMIT_REQUEST,
      userId,
      payload: { ...values },
    };
  },
  submitSuccess: response => {
    return {
      type: ActionType.SUBMIT_SUCCESS,
      payload: { ...response },
    };
  },
  submitFailed: errors => {
    return {
      type: ActionType.SUBMIT_FAILED,
      errors,
    };
  },
};

export default Actions;
