import { takeLatest, put, call } from 'redux-saga/effects';
import { routePath } from 'app/services/helpers'
// -------

import axios from 'app/services/http/axios';

// -------

import Actions from './actions';
import ActionType from './actionTypes';


// -------

import { Api } from 'modules/Admin/Settings'

// -------

function* validate({file}) {
  const { 
    validateDataSuccess, 
    validateDataFailed 
  } = Actions
  try {
    const route = routePath(Api.migration.validate)

    let formData = new FormData()
    formData.append('file', file)

    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
    const response = yield call(axios.post, route, formData);
    yield put(validateDataSuccess(response));

  } catch (e) {
    return yield put(validateDataFailed(e));
  }
}

function* save({ payload }) {

  const { 
    saveFormSuccess, 
    saveFormFailed
  } = Actions

  try {

    const route = routePath(Api.migration.submit)

    axios.defaults.headers.post['Content-Type'] = 'application/json'
    const response = yield call(axios.post, route, { ...payload });
    yield put(saveFormSuccess(response));

  } catch (e) {
    return yield put(saveFormFailed(e));
  }
}

const watchEffects = [
  takeLatest(ActionType.VALIDATE_REQUEST, validate),
  takeLatest(ActionType.SAVE_REQUEST, save),
]

export default watchEffects
