import React, { Fragment, useEffect } from "react"
import { useTranslation } from 'react-i18next';

const PublicLayout = ({children}) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('site.title')
  })

  return (
    <Fragment>{children}</Fragment>
  )

}
export default PublicLayout
