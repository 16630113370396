import { lazy } from 'react'

// -------

import { routes } from 'config/app'
import { Rules } from 'config/permissions'

// -------
const SquilMasterListPage     = lazy(() => import('./MasterList'))
const SquilMasterListViewPage = lazy(() => import('./MasterListView'))
const SquilMasterListFormPage = lazy(() => import('./TransactionForm/masterlist'))

const SquilJobOrderListPage     = lazy(() => import('./JobOrderList'))
const SquilJobOrderFormPage     = lazy(() => import('./JobOrderForm'))
const SquilJobOrderViewPage     = lazy(() => import('./JobOrderView'))
const SquilJobOrderReceivePage  = lazy(() => import('./JobOrderReceive'))
const SquilJobOrderPickPage     = lazy(() => import('./JobOrderPick'))
const SquilJobOrderDestroyPage     = lazy(() => import('./JobOrderDestroy'))
const SquilJobOrderLocationPage = lazy(() => import('./JobOrderLocation'))

const SquilTransactionPickupListPage      = lazy(() => import('./TransactionList/pickup'))
const SquilTransactionPickupFormPage      = lazy(() => import('./TransactionForm/pickup'))
const SquilTransactionPickupViewPage      = lazy(() => import('./TransactionView/pickup'))

const SquilTransactionRetrievalListPage    = lazy(() => import('./TransactionList/retrieval'))
const SquilTransactionRetrievalFormPage    = lazy(() => import('./TransactionForm/retrieval'))
const SquilTransactionRetrievalViewPage    = lazy(() => import('./TransactionView/retrieval'))
const SquilTransactionRetrievalReceivePage = lazy(() => import('./TransactionReceive/retrieval'))

const SquilTransactionRepickupListPage = lazy(() => import('./TransactionList/repickup'))
const SquilTransactionRepickupFormPage = lazy(() => import('./TransactionForm/repickup'))
const SquilTransactionRepickupViewPage = lazy(() => import('./TransactionView/repickup'))

const SquilTransactionAccessListPage      = lazy(() => import('./TransactionList/access'))
const SquilTransactionAccessFormPage = lazy(() => import('./TransactionForm/access'))
const SquilTransactionAccessViewPage = lazy(() => import('./TransactionView/access'))

const SquilTransactionDestructionListPage = lazy(() => import('./TransactionList/destruction'))
const SquilTransactionDestructionFormPage = lazy(() => import('./TransactionForm/destruction'))
const SquilTransactionDestructionViewPage = lazy(() => import('./TransactionView/destruction'))

const WarehouseLocationMovePage = lazy(() => import('./WarehouseLocationMove'))
const BarcodeScanPage = lazy(() => import('./BarcodeScan'))

// -------

export const Api = {
  masterlist: {
    list:    '/squil/masterlist',
    details: '/squil/masterlist/:id/details',
    filters: '/squil/masterlist/filters',
  },

  barcode: {
    scan: '/squil/masterlist/:barcode/barcode-scan',
  },

  warehouse: {
    boxFilters: '/squil/warehouse/location/:id/box-filters',
    move:       '/squil/warehouse/location/:id/move'
  },
  
  jobOrder: {
    list:    '/squil/job-orders',
    form:    '/squil/job-orders/:id',
    details: '/squil/job-orders/:id/details',
    receive: '/squil/job-orders/:id/receive',
    pick:    '/squil/job-orders/:id/pick',
    destroy: '/squil/job-orders/:id/destroy',
    filters: '/squil/job-orders/form-filters',
    location: {
      filters: '/squil/warehouse/location/:companyId/:id/filters',
      save:    '/squil/job-orders/:id/location'
    },
    saveStatus: '/squil/job-orders/:id/save-status',
  },

  transactions: {
    list:    '/squil/transactions/:type',
    form:    '/squil/transactions/:type/:id',
    details: '/squil/transactions/:type/:id/details',
    filters: '/squil/transactions/:type/form-filters',
    uploads: '/squil/transactions/:type/:companyId/uploads',
    receive: '/squil/transactions/:type/:id/receive',
  },

  pdf: {
    pickupListPdf:       '/squil/pdf/job-order/:userIdentifier/:identifier/pickup-list-document',
    returnPickupListPdf: '/squil/pdf/job-order/:userIdentifier/:identifier/return-pickup-list-document',
    stockReceiptPdf:     '/squil/pdf/job-order/:userIdentifier/:identifier/stock-receipt-document',
    barcodePdf:          '/squil/pdf/job-order/:userIdentifier/:identifier/barcodes',
    pickListPdf:         '/squil/pdf/job-order/:userIdentifier/:identifier/pick-list-document',
    deliveryReceiptPdf:  '/squil/pdf/job-order/:userIdentifier/:identifier/delivery-receipt-document',
    destructionPdf:      '/squil/pdf/job-order/:userIdentifier/:identifier/destruction-document',
    accessOnSiteListPdf: '/squil/pdf/job-order/:userIdentifier/:identifier/access-on-site-document',
    permPulloutListPdf:  '/squil/pdf/job-order/:userIdentifier/:identifier/perm-pullout-document',

    transactionPdf:      '/squil/pdf/transaction/:type/:identifier/document',
  },

  excel: {
    masterListReport:  '/squil/excel/masterlist',
    boxHistoryReport:  '/squil/excel/box/:identifier/history-report',
    stockReceiptExcel: '/squil/excel/job-order/:userIdentifier/:identifier/stock-receipt-document',
  },
}


const route = routes.backend + '/squil'
export const Routes = {
  warehouse: {
    location: {
      move: `${route}/warehouse/location/:id/move`
    }
  },
  
  barcodeScan: `${route}/barcode-scan`,
  
  masterlist: {
    index: `${route}/masterlist`,
    view: `${route}/masterlist/:id/view`,
    form: `${route}/masterlist/:id/form`
  },

  jobOrder: {
    index:    `${route}/job-orders`,
    form:     `${route}/job-order/:id/form`,
    view:     `${route}/job-order/:id/view`,
    receive:  `${route}/job-order/:type/:id/receive`,
    pick:     `${route}/job-order/:type/:id/pick`,
    destroy:  `${route}/job-order/:type/:id/destroy`,
    location: `${route}/job-order/:id/location`,
  },

  transaction: {
    pickup: {
      index: `${route}/transaction/pickup`,
      form: `${route}/transaction/pickup/:id/form`,
      view: `${route}/transaction/pickup/:id/view`,
    },
    retrieval: {
      index:   `${route}/transaction/retrieval`,
      form:    `${route}/transaction/retrieval/:id/form`,
      view:    `${route}/transaction/retrieval/:id/view`,
      receive: `${route}/transaction/retrieval/:id/receive`,
    },
    "repickup": {
      index: `${route}/transaction/repickup`,
      form:  `${route}/transaction/repickup/:id/form`,
      view:  `${route}/transaction/repickup/:id/view`,
    },
    access: {
      index: `${route}/transaction/access`,
      form: `${route}/transaction/access/:id/form`,
      view:  `${route}/transaction/access/:id/view`,
    },
    destruction: {
      index: `${route}/transaction/destruction`,
      form: `${route}/transaction/destruction/:id/form`,
      view:  `${route}/transaction/destruction/:id/view`,
    },
  },
}

export const Components = [
  { path: Routes.masterlist.index, component: SquilMasterListPage,     permission: Rules.page.squil.masterlist },
  { path: Routes.masterlist.view,  component: SquilMasterListViewPage, permission: Rules.page.squil.masterlist },
  { path: Routes.masterlist.form,  component: SquilMasterListFormPage, permission: Rules.page.squil.masterlist },

  { path: Routes.jobOrder.index,    component: SquilJobOrderListPage,     permission: Rules.page.squil.jobOrder.index },
  { path: Routes.jobOrder.form,     component: SquilJobOrderFormPage,     permission: Rules.page.squil.jobOrder.form },
  { path: Routes.jobOrder.view,     component: SquilJobOrderViewPage,     permission: Rules.page.squil.jobOrder.view },
  { path: Routes.jobOrder.receive,  component: SquilJobOrderReceivePage,  permission: Rules.page.squil.jobOrder.receive },
  { path: Routes.jobOrder.pick,     component: SquilJobOrderPickPage,     permission: Rules.page.squil.jobOrder.pick },
  { path: Routes.jobOrder.destroy,  component: SquilJobOrderDestroyPage,  permission: Rules.page.squil.jobOrder.destroy },
  { path: Routes.jobOrder.location, component: SquilJobOrderLocationPage, permission: Rules.page.squil.jobOrder.location },

  { path: Routes.transaction.pickup.index, component: SquilTransactionPickupListPage, permission: Rules.page.transactions.pickup },
  { path: Routes.transaction.pickup.form,  component: SquilTransactionPickupFormPage, permission: Rules.page.transactions.pickup },
  { path: Routes.transaction.pickup.view,  component: SquilTransactionPickupViewPage, permission: Rules.page.transactions.pickup },

  { path: Routes.transaction.retrieval.index,   component: SquilTransactionRetrievalListPage,    permission: Rules.page.transactions.retrieval },
  { path: Routes.transaction.retrieval.form,    component: SquilTransactionRetrievalFormPage,    permission: Rules.page.transactions.retrieval },
  { path: Routes.transaction.retrieval.view,    component: SquilTransactionRetrievalViewPage,    permission: Rules.page.transactions.retrieval },
  { path: Routes.transaction.retrieval.receive, component: SquilTransactionRetrievalReceivePage, permission: Rules.page.transactions.retrieval },

  { path: Routes.transaction.repickup.index, component: SquilTransactionRepickupListPage, permission: Rules.page.transactions.repickup },
  { path: Routes.transaction.repickup.form,  component: SquilTransactionRepickupFormPage, permission: Rules.page.transactions.repickup },
  { path: Routes.transaction.repickup.view,  component: SquilTransactionRepickupViewPage, permission: Rules.page.transactions.repickup },

  { path: Routes.transaction.access.index, component: SquilTransactionAccessListPage, permission: Rules.page.transactions.access },
  { path: Routes.transaction.access.form,  component: SquilTransactionAccessFormPage, permission: Rules.page.transactions.access },
  { path: Routes.transaction.access.view,  component: SquilTransactionAccessViewPage, permission: Rules.page.transactions.access },

  { path: Routes.transaction.destruction.index, component: SquilTransactionDestructionListPage, permission: Rules.page.transactions.destruction },
  { path: Routes.transaction.destruction.form,  component: SquilTransactionDestructionFormPage, permission: Rules.page.transactions.destruction },
  { path: Routes.transaction.destruction.view,  component: SquilTransactionDestructionViewPage, permission: Rules.page.transactions.destruction },

  { path: Routes.warehouse.location.move,  component: WarehouseLocationMovePage, permission: Rules.page.warehouse.moveLocation },

  { path: Routes.barcodeScan,  component: BarcodeScanPage, permission: Rules.page.barcodeScan },

]
