
import MasterListReducers from './MasterList/store/reducers'
import MasterListViewReducers from './MasterListView/store/reducers'

import JobOrderListReducers from './JobOrderList/store/reducers'
import JobOrderFormReducers from './JobOrderForm/store/reducers'
import JobOrderViewReducers from './JobOrderView/store/reducers'
import JobOrderReceiveReducers from './JobOrderReceive/store/reducers'
import JobOrderPickReducers from './JobOrderPick/store/reducers'
import JobOrderDestroyReducers from './JobOrderDestroy/store/reducers'
import JobOrderLocationReducers from './JobOrderLocation/store/reducers'

import PickupTransactionListReducers from './TransactionList/store/reducers'
import PickupTransactionFormReducers from './TransactionForm/store/reducers'
import PickupTransactionViewReducers from './TransactionView/store/reducers'
import PickupTransactionReceiveReducers from './TransactionReceive/store/reducers'

import WarehouseLocationMoveReducers from './WarehouseLocationMove/store/reducers'

import BarcodeScanReducers from './BarcodeScan/store/reducers'

export default {
  ...MasterListReducers,
  ...MasterListViewReducers,
  
  ...JobOrderListReducers,
  ...JobOrderFormReducers,
  ...JobOrderViewReducers,
  ...JobOrderReceiveReducers,
  ...JobOrderPickReducers,
  ...JobOrderDestroyReducers,
  ...JobOrderLocationReducers,

  ...PickupTransactionListReducers,
  ...PickupTransactionFormReducers,
  ...PickupTransactionViewReducers,
  ...PickupTransactionReceiveReducers,
  
  ...WarehouseLocationMoveReducers,
  
  ...BarcodeScanReducers,
}
