// -------

import ActionType from './actionTypes'


// -------

const Actions = {

  /**
   * RESET
   */
  initialState: () => {
    return {
      type: ActionType.INITIAL_STATE,
    };
  },
  
  /**
   * VALIDATE
   */
  validateData: (file) => {
    return {
      type: ActionType.VALIDATE_REQUEST,
      file
    };
  },
  validateDataSuccess: response => {
    return {
      type: ActionType.VALIDATE_SUCCESS,
      payload: {
        ...response,
      },
    };
  },
  validateDataFailed: errors => {
    return {
      type: ActionType.VALIDATE_FAILED,
      errors,
    };
  },

  
  /**
   * SAVE
   */
  saveForm: (items) => {
    return {
      type: ActionType.SAVE_REQUEST,
      payload: { items },
    };
  },
  saveFormSuccess: response => {
    return {
      type: ActionType.SAVE_SUCCESS,
      payload: {
        ...response,
      },
    };
  },
  saveFormFailed: errors => {
    return {
      type: ActionType.SAVE_FAILED,
      errors,
    };
  },


};

export default Actions;
