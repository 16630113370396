
import InvoiceListReducers from './Invoice/List/store/reducers'
import InvoiceFormReducers from './Invoice/Form/store/reducers'

import ReportListReducers from './Report/List/store/reducers'
import ReportFormReducers from './Report/Form/store/reducers'

export default {
  ...InvoiceListReducers,
  ...InvoiceFormReducers,
  
  ...ReportListReducers,
  ...ReportFormReducers,
}
