import { produce } from 'immer';

import ActionType from './actionTypes'

// -------

const INITIAL_STATE = {
  errors: {},
  requesting: null,
  expired: null,
  userId: 0,
  verified: null,
  submitting: null,
  saved: null,
};

// -------

const ProduceReducers = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    
    switch (action.type) {

      case ActionType.VERIFY_REQUEST: {
        draft.requesting = true;
        break;
      }
      case ActionType.VERIFY_SUCCESS: {
        const { payload } = action;
        
        draft.userId = payload.userId || null
        draft.verified  = payload.verified || null

        draft.expired = payload.expired || null
        draft.requesting = false;
        break;
      }
      case ActionType.VERIFY_FAILED: {
        draft.errors = action.errors;
        draft.expired = true
        break;
      }

      case ActionType.SUBMIT_REQUEST: {
        draft.submitting = true;
        break;
      }
      case ActionType.SUBMIT_SUCCESS: {
        draft.saved = true;
        break;
      }
      case ActionType.SUBMIT_FAILED: {
        draft.errors = action.errors;
        draft.saved = false;
        draft.submitting = false;
        break;
      }

      default:
        return state;
    }
  });
};

const Reducers = {
  PublicResetPasswordReducer: ProduceReducers,
}
export default Reducers
