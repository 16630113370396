import React, { Suspense, Fragment } from "react"
import { connect } from "react-redux"
import { Route, Routes, BrowserRouter as Router, unstable_HistoryRouter as HistoryRouter } from "react-router-dom"

// Import Routes all
import Components from 'app/components';
import { Ability } from 'app/can'

// Import all middleware
import ProtectedAuthRoute from "app/layouts/protected"

// layouts Format
import AdminLayout from "app/layouts/Admin"
import PublicLayout from "app/layouts/Public"

import { history } from 'app/redux';

import Page404 from 'modules/Error/404';
import PageLoader from 'app/components/PageLoader';
import Toast from 'app/components/Toast';

// i18n
import 'locales/i18n';

// Import scss
import "assets/scss/theme.scss"
import 'react-toastify/dist/ReactToastify.min.css';
import "react-datepicker/dist/react-datepicker.css";

const App = ({ User, isLogged }) => {

  const authProps = { 
    User, 
    UserRole:          User.role,
    CompanyId:         User.company_id,
    CompanyIdentifier: User.company,
    isLogged 
  }

  return (

    <Fragment>
      <Toast />

      <Suspense fallback={<PageLoader blocker={true} />}>
        <HistoryRouter history={history}>

          <Routes>
            {Components.map((app, key) => {
              return (
                <Fragment key={key}>
                {app.public === true ? (
                  <Route
                    path={app.path}
                    element={<PublicLayout> <app.component /> </PublicLayout>}
                    history={history}
                  />
                ) : (
                    <Route
                      path={app.path}
                      element={
                          <ProtectedAuthRoute {...authProps}>
                            <AdminLayout>
                            { 
                              ( !app.permission || Ability.can('allowed', app.permission) )
                              ? <app.component {...authProps} />
                              : <Page404 />
                             }
                            </AdminLayout>
                          </ProtectedAuthRoute>
                      }
                      history={history}
                    />
                )}
                </Fragment>
              );
            })}
              <Route path="*" element={ <Page404 /> } />
          </Routes>
          
        </HistoryRouter>
      </Suspense>
    </Fragment>
  )
}

const mapStateToProps = state => {

  const auth = state.auth
  return { 
    User: auth.user || {},
    isLogged: auth.isLogged || false,
  }
}

export default connect(mapStateToProps)(App)
