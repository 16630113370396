import { Ability, createMongoAbility, AbilityBuilder } from "@casl/ability"
import { store } from 'app/redux';
import Permissions, {AdditionalRules} from 'config/permissions';

const ability = createMongoAbility();

const extract = (can, rule) => {
  if ( Array.isArray(rule) ) {
    can(rule[0], rule[1])
  } else {
    can('allowed', rule)
  }
}

const defineRulesFor = (auth) => {
  const { can, cannot, rules } = new AbilityBuilder();

    const allowedAll = Permissions.all
    allowedAll.forEach((rule) => {
      extract(can, rule)
    })

    const additionalAllowedAll = AdditionalRules
    additionalAllowedAll.forEach((rule) => {
      let isRule = false
      switch (rule[1]) {
        case 'auth:client':
          if ( auth.user.company !== null ) isRule = true
          break;
      }
      if ( isRule === true ) {
        can(rule[0], rule[1])
      } else {
        cannot(rule[0], rule[1])
      }
    })

    const perRoles = Permissions[auth.user.role] || null
    can('allowed', auth.user.role)

    if ( perRoles ) {
      perRoles.forEach((rule) => {
        extract(can, rule)
      })
    }

  console.log('ROOT: Rules', rules)
  
  return rules
}

let authLogged

const authDefineRules = (store) => {
  const oldAuthLogged = authLogged
  const { auth } = store.getState();

  authLogged = auth.isLogged
  if (oldAuthLogged !== authLogged) {
    ability.update(defineRulesFor(auth))
  }
}

authDefineRules(store)
store.subscribe(() => {
  authDefineRules(store)
})


export default ability