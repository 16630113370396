// layout
import Layout from 'app/layouts/store/effects';

import PublicSignIn from 'modules/Public/SignIn/store/effects'
import PubliClientAccount from 'modules/Public/ClientAccount/store/effects'
import PubliForgotPassword from 'modules/Public/ForgotPassword/store/effects'
import PubliResetPassword from 'modules/Public/ResetPassword/store/effects'

import AdminProfile from 'modules/Admin/Profile/effects'
import AdminAccounts from 'modules/Admin/Accounts/effects'
import AdminClients from 'modules/Admin/Clients/effects'
import AdminSquil from 'modules/Admin/Squil/effects'
import AdminSettings from 'modules/Admin/Settings/effects'
import AdminReports from 'modules/Admin/Reports/effects'


const rootEffects = [
  ...Layout,

  // Admin
    ...AdminProfile,
    ...AdminAccounts,
    ...AdminClients,
    ...AdminSquil,
    ...AdminSettings,
    ...AdminReports,
  
  // Public
    ...PublicSignIn,
    ...PubliClientAccount,
    ...PubliForgotPassword,
    ...PubliResetPassword,

]
console.log('ROOT:SagaEffects', rootEffects);

export default rootEffects;
