
export const objectToUrl = (collections, isFirst) => {
  let uri = ( isFirst === true ) ? '?' : ''
  uri += Object.entries(collections)
                  .map(([key, value]) => `${key}=${value}`)
                  .join("&")
  return uri
}

export const routePath = (uri, obj) => {
  if ( Array.isArray(uri) === true ) uri = routePath(uri[0], uri[1])
  const matches = uri.split(':')
  if ( matches && matches.length > 1 ) {
      matches.forEach(key => {
        const accessor  = key.split('/')[0] || null 
        if ( accessor && obj[accessor]) uri = uri.replace(`:${accessor}`, obj[accessor])
      })
  }
  return uri
}

export const directPath = (uri, obj) => {
  const DIRECT_URL = process.env.REACT_APP_DIRECT_URL;
  const baseUrl    = DIRECT_URL.replace(/^\/|\/$/g, '')

  return baseUrl + routePath(uri, obj)
}


export const transactionType = (type) => {
  let result = ' '
  switch (type) {
    case 'pickup':      result = 'Pickup';         break;
    case 'delivery':    result = 'Retrieval';      break;
    case 'repickup':    result = 'Return';         break;
    case 'destruction': result = 'Destruction';    break;
    case 'access':      result = 'Access-On-Site'; break;
  }
  return result
}