import { applyMiddleware, compose } from 'redux';

// -------

const enhancer = middlewares => {
  return process.env.NODE_ENV === 'development' && console.tron
    ? compose(console.tron.createEnhancer(), applyMiddleware(...middlewares))
    : applyMiddleware(...middlewares);
};

export default enhancer;
