
import TransactionTypesListReducers from './TransactionTypes/List/store/reducers'
import TransactionTypesFormReducers from './TransactionTypes/Form/store/reducers'

import UnitsListReducers from './Units/List/store/reducers'
import UnitsFormReducers from './Units/Form/store/reducers'

import PersonsListReducers from './Persons/List/store/reducers'
import PersonsFormReducers from './Persons/Form/store/reducers'

import WarehouseLocationListReducers from './WarehouseLocation/List/store/reducers'
import WarehouseLocationFormReducers from './WarehouseLocation/Form/store/reducers'

import MigrationReducers from './Migration/Index/store/reducers'

export default {
  ...TransactionTypesListReducers,
  ...TransactionTypesFormReducers,

  ...UnitsListReducers,
  ...UnitsFormReducers,

  ...PersonsListReducers,
  ...PersonsFormReducers,

  ...WarehouseLocationListReducers,
  ...WarehouseLocationFormReducers,

  ...MigrationReducers,
}
