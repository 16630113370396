
const ActionTypes = (module, actions = {}) => {
  return {
    LIST_REQUEST: `${module}.list/REQUEST/list`,
    LIST_SUCCESS: `${module}.list/SUCCESS/list`,
    LIST_FAILED: `${module}.list/FAILED/list`,

    DELETE_REQUEST: `${module}.list/REQUEST/delete`,
    DELETE_SUCCESS: `${module}.list/SUCCESS/delete`,
    DELETE_FAILED: `${module}.list/FAILED/delete`,

    ...actions,
  }
}

export default ActionTypes