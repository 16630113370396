// Admin
import { Components as Dashboard } from 'modules/Admin/Dashboard';
import { Components as Squil } from 'modules/Admin/Squil';
import { Components as Settings } from 'modules/Admin/Settings';
import { Components as Accounts } from 'modules/Admin/Accounts';
import { Components as Clients } from 'modules/Admin/Clients';
import { Components as Profile } from 'modules/Admin/Profile';
import { Components as Reports } from 'modules/Admin/Reports';

// Public
import { PublicComponents } from 'modules/Public';

const routeComponents = [

  ...Dashboard,
  ...Squil,
  ...Settings,
  ...Accounts,
  ...Clients,
  ...Profile,
  ...Reports,

  ...PublicComponents,
  
]
// console.log('ROOT: Components', routeComponents)

export default routeComponents;
