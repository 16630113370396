import { createStore } from 'redux';
import { persistStore } from 'redux-persist';
import { createBrowserHistory } from 'history';

// -------

import reducers from './reducers';
import enhancer from './enhancer';
import middleware from './middleware';
import persist from './persist';
import effects from './effects';

// -------

const history = createBrowserHistory()

const persisted_reducer = persist(reducers);
const composed_enhancer = enhancer([middleware]);

const store = createStore(persisted_reducer, composed_enhancer);
const persistor = persistStore(store);

middleware.run(effects);

export { store, history, persistor };
