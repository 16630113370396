import { takeLatest, put, call } from 'redux-saga/effects';

// -------

import axios from 'app/services/http/axios';
import Actions from './actions';

import { Api } from 'modules/Admin/Profile'

// -------

function* get() {
  const { 
    getProfileSuccess, 
    getProfileFailed 
  } = Actions

  try {
    const route = Api.user;
    const response = yield call(axios.get, route);
    yield put(getProfileSuccess(response));

  } catch (e) {
    return yield put(getProfileFailed(e));
  }
}

function* save({ payload }) {

  const { 
    saveProfileSuccess, 
    saveProfileFailed
  } = Actions

  try {
    const route = Api.profile;
    const response = yield call(axios.post, route, { ...payload });
    yield put(saveProfileSuccess(response));

  } catch (e) {
    return yield put(saveProfileFailed(e));
  }
}

// -------
 
const { 
  PROFILE_REQUEST,
  SAVE_PROFILE_REQUEST
} = Actions

const watchEffects = [
  takeLatest(PROFILE_REQUEST, get),
  takeLatest(SAVE_PROFILE_REQUEST, save)
]

export default watchEffects
