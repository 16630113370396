
import MasterListEffects from './MasterList/store/effects'
import MasterListViewEffects from './MasterListView/store/effects'

import JobOrderListEffects from './JobOrderList/store/effects'
import JobOrderFormEffects from './JobOrderForm/store/effects'
import JobOrderViewEffects from './JobOrderView/store/effects'
import JobOrderReceiveEffects from './JobOrderReceive/store/effects'
import JobOrderPickEffects from './JobOrderPick/store/effects'
import JobOrderDestroyEffects from './JobOrderDestroy/store/effects'
import JobOrderLocationEffects from './JobOrderLocation/store/effects'

import PickupTransactionListEffects from './TransactionList/store/effects'
import PickupTransactionFormEffects from './TransactionForm/store/effects'
import PickupTransactionViewEffects from './TransactionView/store/effects'
import PickupTransactionReceiveEffects from './TransactionReceive/store/effects'

import WarehouseLocationMoveEffects from './WarehouseLocationMove/store/effects'

import BarcodeScanEffects from './BarcodeScan/store/effects'

export default [
  ...MasterListEffects,
  ...MasterListViewEffects,
  
  ...JobOrderListEffects,
  ...JobOrderFormEffects,
  ...JobOrderViewEffects,
  ...JobOrderReceiveEffects,
  ...JobOrderPickEffects,
  ...JobOrderDestroyEffects,
  ...JobOrderLocationEffects,

  ...PickupTransactionListEffects,
  ...PickupTransactionFormEffects,
  ...PickupTransactionViewEffects,
  ...PickupTransactionReceiveEffects,
  
  ...WarehouseLocationMoveEffects,
  
  ...BarcodeScanEffects,
]
