// -------

import {
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_FAILED,

  SAVE_PROFILE_REQUEST,
  SAVE_PROFILE_SUCCESS,
  SAVE_PROFILE_FAILED,

} from './actionTypes'

// -------

const Actions = {
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_FAILED,

  SAVE_PROFILE_REQUEST,
  SAVE_PROFILE_SUCCESS,
  SAVE_PROFILE_FAILED,

  /**
   * GET
   */
  getProfile: () => {
    return {
      type: PROFILE_REQUEST
    };
  },
  getProfileSuccess: response => {
    return {
      type: PROFILE_SUCCESS,
      payload: {
        ...response,
      },
    };
  },
  getProfileFailed: errors => {
    return {
      type: PROFILE_FAILED,
      errors,
    };
  },

  /**
   * POST
   */
  saveProfile: values => {
    return {
      type: SAVE_PROFILE_REQUEST,
      payload: { ...values },
    };
  },
  saveProfileSuccess: response => {
    return {
      type: SAVE_PROFILE_SUCCESS,
      payload: {
        ...response
      },
    };
  },
  saveProfileFailed: errors => {
    return {
      type: SAVE_PROFILE_FAILED,
      errors,
    };
  },


};

export default Actions;
