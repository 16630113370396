import { lazy } from 'react';

const PublicHome = lazy(() => import('./Home'));

const SignIn = lazy(() => import('./SignIn'));
const SignOut = lazy(() => import('./SignOut'));

const ForgotPassword = lazy(() => import('./ForgotPassword'));
const ResetPassword = lazy(() => import('./ResetPassword'));

const ClientAccount = lazy(() => import('./ClientAccount'));

// -------

export const PublicApi = {
  login: '/auth/login',

  // Reset Password
  forgotPassword: '/auth/forgot-password',
  resetPasswordVerify: '/auth/reset-password/verify/:identifier',
  resetPassword: '/auth/reset-password/:id',


  // Client Verify and Creating account
  clientVerify: '/client/verify/:identifier',
  clientSave : '/client/account/:companyId/create',
}

export const PublicRoutes = {
  home: '/',
  signin: '/signin',
  password: {
    forgot: '/forgot-password',
    reset: '/reset-password/:identifier'
  },
  client: {
    account: 'client/account/:identifier'
  },
  signout: '/signout',
};

export const PublicComponents = [
  { public: true, path: PublicRoutes.home, component: PublicHome },
  { public: true, path: PublicRoutes.signin, component: SignIn },
  { public: true, path: PublicRoutes.signout, component: SignOut },
  { public: true, path: PublicRoutes.password.forgot, component: ForgotPassword },
  { public: true, path: PublicRoutes.password.reset, component: ResetPassword },
  { public: true, path: PublicRoutes.client.account, component: ClientAccount },
];
