// -------

import ActionTypes from './actionTypes'

// -------

const Actions = {

  /**
   * GET
   */
  submit: values => {
    return {
      type: ActionTypes.SUBMIT_REQUEST,
      payload: { ...values },
    };
  },
  submitSuccess: response => {
    return {
      type: ActionTypes.SUBMIT_SUCCESS,
      payload: {
        ...response
      },
    };
  },
  submitFailed: errors => {
    return {
      type: ActionTypes.SUBMIT_FAILED,
      errors,
    };
  },
};

export default Actions;
