import React from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

//Import Images
import errorImg from "assets/img/404-error.png" 

import RouteConst from 'app/routes'

const Pages404 = () => {
  
  return (
    <React.Fragment>
      <div className="my-5 pt-sm-5">
        <Container>
          <Row>
            <Col md={12}>
              <div className="text-center">
                <h2 className="text-uppercase mt-4">404 - Page not found</h2>
                <p className="text-muted">The page you requested could not be found.</p>
                <div className="mt-5">
                  <Link className="btn btn-outline-primary btn-sm" to={RouteConst.dashboard.index}>Back to Dashboard</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Pages404
