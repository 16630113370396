

const ActionTypes = {
  INITIAL_STATE: '@admin.accounts.form/REQUEST/initial-state',

  REQUEST: '@admin.accounts.form/REQUEST/get',
  SUCCESS: '@admin.accounts.form/SUCCESS/get',
  FAILED: '@admin.accounts.form/FAILED/get',

  FILTERS_REQUEST: '@admin.accounts.form/REQUEST/filters',
  FILTERS_SUCCESS: '@admin.accounts.form/SUCCESS/filters',
  FILTERS_FAILED: '@admin.accounts.form/FAILED/filters',

  SAVE_REQUEST: '@admin.accounts.form/REQUEST/save',
  SAVE_SUCCESS: '@admin.accounts.form/SUCCESS/save',
  SAVE_FAILED: '@admin.accounts.form/FAILED/save'
}

export default ActionTypes