// -------

import ActionType from './actionTypes'

// -------

const Actions = {

  /**
   * VERIFY
   */
  verify: identifier => {
    return {
      type: ActionType.VERIFY_REQUEST,
      identifier,
    };
  },
  verifySuccess: response => {
    return {
      type: ActionType.VERIFY_SUCCESS,
      payload: { ...response },
    };
  },
  verifyFailed: errors => {
    return {
      type: ActionType.VERIFY_FAILED,
      errors,
    };
  },

  /**
   * SUBMIT
   */
  saveForm: (companyId, values) => {
    return {
      type: ActionType.SAVE_REQUEST,
      companyId,
      payload: { ...values },
    };
  },
  saveFormSuccess: response => {
    return {
      type: ActionType.SAVE_SUCCESS,
      payload: { ...response },
    };
  },
  saveFormFailed: errors => {
    return {
      type: ActionType.SAVE_FAILED,
      errors,
    };
  },
};

export default Actions;
