import { takeLatest, put, call } from 'redux-saga/effects';
import { routePath } from 'app/services/helpers'
// -------

import axios from 'app/services/http/axios';

// -------

import Actions from './actions';
import ActionType from './actionTypes';

import { Api } from 'modules/Admin/Settings'

// -------

function* get({id}) {
  const {  getFormDataSuccess, getFormDataFailed } = Actions

  try {
    const route = routePath(Api.transactionTypes.form, { id })

    const response = yield call(axios.get, route);
    yield put(getFormDataSuccess(response));

  } catch (e) {
    return yield put(getFormDataFailed(e));
  }
}

function* save({ id, payload }) {
  const { saveFormSuccess, saveFormFailed } = Actions
  try {

    if ( id === 0 ) id = 'create'
    const route = routePath(Api.transactionTypes.form, { id })

    const response = yield call(axios.post, route, { ...payload });
    yield put(saveFormSuccess(response));

  } catch (e) {
    return yield put(saveFormFailed(e));
  }
}

const watchEffects = [
  takeLatest(ActionType.REQUEST, get),
  takeLatest(ActionType.SAVE_REQUEST, save),
]

export default watchEffects
