

const ActionTypes = {
  INITIAL_STATE: '@admin.reports.invoice.form/STATE/initial-state',

  GET_REQUEST: '@admin.reports.invoice.form/REQUEST/get',
  GET_SUCCESS: '@admin.reports.invoice.form/SUCCESS/get',
  GET_FAILED: '@admin.reports.invoice.form/FAILED/get',

  FILTERS_REQUEST: '@admin.reports.invoice.form/REQUEST/filters',
  FILTERS_SUCCESS: '@admin.reports.invoice.form/SUCCESS/filters',
  FILTERS_FAILED: '@admin.reports.invoice.form/FAILED/filters',

  FIND_FILTER_REQUEST: '@admin.reports.invoice.form/REQUEST/find-filter',
  FIND_FILTER_SUCCESS: '@admin.reports.invoice.form/SUCCESS/find-filter',
  FIND_FILTER_FAILED: '@admin.reports.invoice.form/FAILED/find-filter',

  SAVE_REQUEST: '@admin.reports.invoice.form/REQUEST/save',
  SAVE_SUCCESS: '@admin.reports.invoice.form/SUCCESS/save',
  SAVE_FAILED: '@admin.reports.invoice.form/FAILED/save'
}

export default ActionTypes