import { produce } from 'immer';

import ActionTypes from './actionTypes'

// -------

const INITIAL_STATE = {
  errors: {},
  submitting: null,
  sent: null,
};
// -------

const ProduceReducers = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case ActionTypes.SUBMIT_REQUEST: {
        draft.errors = {};
        draft.submitting = true;
        break;
      }
      case ActionTypes.SUBMIT_SUCCESS: {
        const { payload } = action;
        draft.sent      = payload.sent || false
        break;
      }
      case ActionTypes.SUBMIT_FAILED: {
        draft.sent       = null;
        draft.errors     = action.errors;
        draft.submitting = false;
        break;
      }
      default:
        return state;
    }
  });
};

const Reducer = {
  PublicForgotPasswordReducer: ProduceReducers,
};

export default Reducer;
