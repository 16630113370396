

const ActionTypes = {
  INITIAL_STATE: '@admin.squil.jobOrder.receive/REQUEST/initial-state',

  GET_REQUEST: '@admin.squil.jobOrder.receive/REQUEST/get',
  GET_SUCCESS: '@admin.squil.jobOrder.receive/SUCCESS/get',
  GET_FAILED: '@admin.squil.jobOrder.receive/FAILED/get',

  SAVE_REQUEST: '@admin.squil.jobOrder.receive/REQUEST/save',
  SAVE_SUCCESS: '@admin.squil.jobOrder.receive/SUCCESS/save',
  SAVE_FAILED: '@admin.squil.jobOrder.receive/FAILED/save'
  
}

export default ActionTypes