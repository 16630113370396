// -------

import ActionType from './actionTypes'


// -------

const Actions = {

  /**
   * RESET
   */
  initialState: () => {
    return {
      type: ActionType.INITIAL_STATE,
    };
  },

  /**
   * GET
   */
  getFormData: (id) => {
    return {
      type: ActionType.GET_REQUEST,
      id
    };
  },
  getFormDataSuccess: response => {
    return {
      type: ActionType.GET_SUCCESS,
      payload: {
        ...response,
      },
    };
  },
  getFormDataFailed: errors => {
    return {
      type: ActionType.GET_FAILED,
      errors,
    };
  },

  getFilters: () => {
    return {
      type: ActionType.FILTERS_REQUEST
    };
  },
  getFiltersSuccess: response => {
    return {
      type: ActionType.FILTERS_SUCCESS,
      payload: {
        ...response,
      },
    };
  },
  getFiltersFailed: errors => {
    return {
      type: ActionType.FILTERS_FAILED,
      errors,
    };
  },
  
  /**
   * SAVE
   */
  findFilter: (values) => {
    return {
      type: ActionType.FIND_FILTER_REQUEST,
      payload: { ...values },
    };
  },
  findFilterSuccess: response => {
    return {
      type: ActionType.FIND_FILTER_SUCCESS,
      payload: {
        ...response,
      },
    };
  },
  findFilterFailed: errors => {
    return {
      type: ActionType.FIND_FILTER_FAILED,
      errors,
    };
  },

  /**
   * SAVE
   */
  saveForm: (values) => {
    return {
      type: ActionType.SAVE_REQUEST,
      payload: { ...values },
    };
  },
  saveFormSuccess: response => {
    return {
      type: ActionType.SAVE_SUCCESS,
      payload: {
        ...response,
      },
    };
  },
  saveFormFailed: errors => {
    return {
      type: ActionType.SAVE_FAILED,
      errors,
    };
  },


};

export default Actions;
