import { lazy } from 'react'

// -------

import { routes } from 'config/app'

// -------

const ProfilePage = lazy(() => import('./Profile'))
const SecurityPage = lazy(() => import('./Security'))

// -------

const route = routes.backend + '/user'

// -------

export const Api = {
  user     : '/user',
  password : '/user/password',
  profile  : '/user/profile'
}

export const Routes = {
  profile   : `${route}/profile`,
  security  : `${route}/security`,
}

export const Components = [
  { path: Routes.profile,  component: ProfilePage },
  { path: Routes.security, component: SecurityPage },
]
