import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

// -------

import reducers from 'app/reducers';

// -------

const reducerConfig = {
  ...reducers,
  router: routerReducer,
};

export default combineReducers(reducerConfig);
