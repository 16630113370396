import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { Link } from "react-router-dom"

import Menus from 'config/menu'
import Can from 'app/can'

import { useTranslation } from 'react-i18next';

import { useLocation } from 'react-router-dom';

//i18n

const SidebarContent = props => {
  const ref = useRef();
  const { t } = useTranslation();
  const location = useLocation();

  const activateParentDropdown = useCallback((item) => {

    removeActiveMenu()

    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  },[]);  

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    let pathName = location.pathname
    const paths = pathName.split('/')
    // if ( paths.length > 3 ) {
    //   const mainPath = paths.filter((p, i) => (i < 3))
    //   console.log('mainPath', mainPath)
    // }

    new MetisMenu("#side-menu")
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }

  }, [location.pathname,activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function removeActiveMenu() {

    const allElements = document.querySelectorAll('.metismenu li');
    allElements.forEach((element) => {
      if ( element.classList.contains('mm-active') ) {
        element.classList.remove('mm-active')
        const childrenElement = element.getElementsByTagName('a');
          if ( childrenElement[0].classList.contains('active') ) {
            childrenElement[0].classList.remove('active')
          }
      }
    });
  }

  return (
    <React.Fragment>
      
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref} className="sidebar-menu-scroll">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">

          {Menus.map((menu, key) => {
            return <Can I="allowed" a={menu.permission} key={key}>
              {
                ( menu.to ? (
                  <li>
                    <Link to={menu.to} className='waves-effect'>
                      <i className={menu.icon}></i>
                      {
                        menu.badge && (
                          <span className={`badge rounded-pill float-end ${menu.badgeColor}`}>
                            {menu.badge}
                          </span>
                        )
                      }
                      <span>{t(menu.title)}</span>
                    </Link>
                  </li>  
                ) : (<li className="menu-title">{t(menu.title)}</li>) )
              }
            </Can>
          })}

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default SidebarContent