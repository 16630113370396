

const ActionTypes = {
  INITIAL_STATE: '@admin.clients.company.form/STATE/initial-state',

  GET_REQUEST: '@admin.clients.company.form/REQUEST/get',
  GET_SUCCESS: '@admin.clients.company.form/SUCCESS/get',
  GET_FAILED: '@admin.clients.company.form/FAILED/get',

  SAVE_REQUEST: '@admin.clients.company.form/REQUEST/save',
  SAVE_SUCCESS: '@admin.clients.company.form/SUCCESS/save',
  SAVE_FAILED: '@admin.clients.company.form/FAILED/save'
}

export default ActionTypes