

const ActionTypes = {
  INITIAL_STATE: '@admin.squil.masterlist.view/REQUEST/initial-state',

  GET_REQUEST: '@admin.squil.masterlist.view/REQUEST/get',
  GET_SUCCESS: '@admin.squil.masterlist.view/SUCCESS/get',
  GET_FAILED: '@admin.squil.masterlist.view/FAILED/get'
}

export default ActionTypes