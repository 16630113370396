// -------
import {
  SIGNOUT_REQUEST,
  SIGNOUT_SUCCESS,
} from './actionTypes'
// -------

const Actions = {
  SIGNOUT_REQUEST,
  SIGNOUT_SUCCESS,

  /**
   * GET
   */
  signOut: () => {
    return {
      type: SIGNOUT_REQUEST,
    };
  },

  signOutSuccess: () => {
    return {
      type: SIGNOUT_SUCCESS,
    };
  },
};

export default Actions;
