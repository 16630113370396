import { produce } from 'immer';
import { v4 as uuidv4 } from 'uuid';

// -------

import cookie from 'app/services/system/cookie';

import SignOutActions from 'modules/Admin/Profile/SignOut/store/actions'
import ActionTypes from './actionTypes'

const {
  SIGNOUT_SUCCESS
} = SignOutActions

// -------

const uuid = cookie.get('uuid') || uuidv4();
const isLogged = cookie.get('isLogged');

const INITIAL_STATE = {
  user: cookie.get('user') || { uuid },
  isLogged: isLogged ? isLogged === 'true' : false,
  token: cookie.get('token') || null,
};

// -------

const ProduceReducers = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case ActionTypes.SIGNIN_REQUEST: {
        draft.requesting = true;
        break;
      }
      case ActionTypes.SIGNIN_SUCCESS: {
        const { payload } = action;

        draft.user     = payload.user;
        draft.isLogged = payload.isLogged;
        draft.token    = payload.token;
        
        delete draft.errors;
        delete draft.requesting;
        break;
      }
      case ActionTypes.SIGNIN_FAILED:
      case SIGNOUT_SUCCESS: {
        draft.user = { uuid };
        draft.isLogged = false;
        draft.token = null;
        draft.errors = action.errors;
        delete draft.requesting;
        break;
      }
      default:
        return state;
    }
  });
};

const SignInReducers = {
  auth: ProduceReducers, // ROOT: Passport Token Authentication
  PublicSignInReducer: ProduceReducers,
};

export default SignInReducers;
