
const ActionTypes = {
  INITIAL_STATE: '@admin.settings.migration.index/STATE/initial-state',

  VALIDATE_REQUEST: '@admin.settings.migration.index/REQUEST/validate',
  VALIDATE_SUCCESS: '@admin.settings.migration.index/SUCCESS/validate',
  VALIDATE_FAILED: '@admin.settings.migration.index/FAILED/validate',

  SAVE_REQUEST: '@admin.settings.migration.index/REQUEST/save',
  SAVE_SUCCESS: '@admin.settings.migration.index/SUCCESS/save',
  SAVE_FAILED: '@admin.settings.migration.index/FAILED/save'
}

export default ActionTypes