import ActionType from './actionTypes'

// -------

const Actions = {

  // ----- LIST ----- 

    getList: (transactionType, pageNumber, filters) => {
      return {
        type: ActionType.LIST_REQUEST,
        transactionType,
        pageNumber,
        filters
      };
    },
    getListSuccess: response => {
      return {
        type: ActionType.LIST_SUCCESS,
        payload: {
          ...response,
        },
      };
    },
    getListFailed: errors => {
      return {
        type: ActionType.LIST_FAILED,
        errors,
      };
    },


  // ----- PDF Download ----- 
    pdfDownload: (transactionType, identifier) => {
      return {
        type: ActionType.PDF_DOWNLOAD_REQUEST,
        transactionType,
        identifier
      };
    },


    // ----- DELETE ----- 
      toDelete: (id) => {
        return {
          type: ActionType.DELETE_REQUEST,
          id
        };
      },
    toDeleteSuccess: () => {
      return {
        type: ActionType.DELETE_SUCCESS
      };
    },
    toDeleteFailed: errors => {
      return {
        type: ActionType.DELETE_FAILED,
        errors,
      };
    },

};

export default Actions;
