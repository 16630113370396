import CommonListReducers from 'modules/Admin/Common/List/store/reducers'

import ActionType from './actionTypes'

const ProduceReducers = (state = {}, action) => CommonListReducers(state, action, ActionType)

const Reducers = {
  AdminSettingsTransactionTypeListReducers: ProduceReducers
}

export default Reducers