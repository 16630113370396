import React from "react"
import {
  Dropdown
} from "react-bootstrap"

import Avatar from 'react-avatar'

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"

import RouteConst from 'app/routes'

import Actions from 'modules/Admin/Profile/SignOut/store/actions'

// users
import user4 from "assets/img/avatar.jpg"
import { Link } from "react-router-dom"

const ProfileMenu = ({t, user, signOut}) => {
  // Declare a new state variable, which we'll call "menu"
  const username = user.username

  const handleSignOut = () => {
    signOut()
  };

  return (
    <React.Fragment>
      <Dropdown
        className="d-inline-block header-main"
      >
        <Dropdown.Toggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user4}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">{username}</span>{" "}
          <i className="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
        </Dropdown.Toggle>  
        <Dropdown.Menu className="dropdown-menu-end">

          <Link to={RouteConst.profile.profile} className="dropdown-item">
            <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
            Profile
          </Link>

          <Dropdown.Item onClick={handleSignOut}>
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-danger"></i>
            <span className="text-danger">{t("Logout")}</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {

  const user = state.auth.user
  return { 
    user
  }
}

const { signOut } = Actions

export default connect(mapStatetoProps, {
  signOut
})(withTranslation()(ProfileMenu))
