
import ProfileEffects from './Profile/store/effects'
import SecurityEffects from './Security/store/effects'
import SignOutEffects from './SignOut/store/effects'

export default [
  ...ProfileEffects,
  ...SecurityEffects,
  ...SignOutEffects
]
