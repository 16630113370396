import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  SHOW_SIDEBAR,
  CHANGE_PRELOADER,
  TOGGLE_LEFTMENU,
} from "./actionTypes"

const Actions = {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  SHOW_SIDEBAR,
  CHANGE_PRELOADER,
  TOGGLE_LEFTMENU,
  
  changeLayout: layout => ({
    type: CHANGE_LAYOUT,
    payload: layout,
  }),

  changePreloader: layout => ({
    type: CHANGE_PRELOADER,
    payload: layout,
  }),

  changeLayoutWidth: width => ({
    type: CHANGE_LAYOUT_WIDTH,
    payload: width,
  }),

  changeSidebarTheme: theme => {
    return {
      type: CHANGE_SIDEBAR_THEME,
      payload: theme,
    }
  },

  changeSidebarType: (sidebarType, isMobile) => {
    return {
      type: CHANGE_SIDEBAR_TYPE,
      payload: { sidebarType, isMobile },
    }
  },

  changeTopbarTheme: topbarTheme => ({
    type: CHANGE_TOPBAR_THEME,
    payload: topbarTheme,
  }),

  showRightSidebarAction: isopen => ({
    type: SHOW_RIGHT_SIDEBAR,
    payload: isopen,
  }),

  showSidebar: isopen => ({
    type: SHOW_SIDEBAR,
    payload: isopen,
  }),

  toggleLeftmenu: isopen => ({
    type: TOGGLE_LEFTMENU,
    payload: isopen,
  }),


};

export default Actions
