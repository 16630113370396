
export const Rules = {
  menu: {
    app          : "menu:app",
    squil        : "menu:squil",
    reports      : "menu:reports",
    settings     : "menu:settings",
    transactions : "menu:transactions",

    dashboard: "menu:dashboard",

    jobOrder    : "menu:squil.jobOrder",
    masterlist  : "menu:squil.masterlist",
    barcodeScan : "menu:squil.barcodeScan",

    transaction: {
      pickup      : "menu:transaction.pickup",
      retrieval   : "menu:transaction.retrieval",
      repickup    : "menu:transaction.repickup",
      access      : "menu:transaction.access",
      destruction : "menu:transaction.destruction",
    },
    accounts          : "menu:accounts",
    clients           : "menu:clients",
    pageSettings      : "menu:settings.pageSettings",
    persons           : "menu:settings.persons",
    departments       : "menu:settings.departments",
    users             : "menu:settings.users",
    warehouseLocation : "menu:settings.warehouseLocation",
    migration         : "menu:settings.migration",

    invoice: "menu:reports.invoice",
    reports: "menu:reports.reports",
  },

  page: {
    accounts: {
      index: "page:accounts.index"
    },
    clients: {
      admin: "page:clients.admin.index",
      client: "page:clients.client.index"
    },
    squil: {
      jobOrder: {
        index: "page:squil.jobOrder.index",
        form: "page:squil.jobOrder.form",
        view: "page:squil.jobOrder.view",
        receive: "page:squil.jobOrder.receive",
        pick: "page:squil.jobOrder.pick",
        destroy: "page:squil.jobOrder.destroy",
        location: "page:squil.jobOrder.location",
      },
      masterlist: "page:squil.masterlist",
      barcodeScan: "page:squil.barcodeScan",
    },
    transactions: {
      pickup:      "page:transactions.pickup",
      retrieval:   "page:transactions.retrieval",
      repickup:    "page:transactions.repickup",
      access:      "page:transactions.access",
      destruction: "page:transactions.destruction",
    },
    settings: {
      index:            "page:settings.index",
      persons:          "page:settings.persons",
      transactionTypes: "page:settings.transactionTypes",
      units:            "page:settings.units",
      migration:        "page:settings.migration",
    },
    warehouse: {
      moveLocation: "page:warehouse.moveLocation",
    },
    reports: {
      invoice: "page:reports.invoice",
      report: "page:reports.report",
    }
  }
}

export const AdditionalRules = [
  ['access', 'auth:client']
]

const permissions = {
  all: [
    Rules.menu.app,
    Rules.menu.dashboard,
  ],
  
  superadmin: [
    Rules.menu.squil,
    Rules.menu.reports,
    Rules.menu.settings,
    Rules.menu.transactions,
    Rules.menu.masterlist,
    Rules.menu.jobOrder,
    Rules.menu.barcodeScan,
    Rules.menu.transaction.pickup,
    Rules.menu.transaction.retrieval,
    Rules.menu.transaction.repickup,
    Rules.menu.transaction.access,
    Rules.menu.transaction.destruction,
    Rules.menu.pageSettings,
    Rules.menu.accounts,
    Rules.menu.clients,
    Rules.menu.persons,
    Rules.menu.warehouseLocation,
    Rules.menu.invoice,
    Rules.menu.reports,
    Rules.menu.migration,

    Rules.page.squil.masterlist,
    Rules.page.squil.jobOrder.index,
    Rules.page.squil.jobOrder.form,
    Rules.page.squil.jobOrder.view,
    Rules.page.squil.jobOrder.receive,
    Rules.page.squil.jobOrder.pick,
    Rules.page.squil.jobOrder.destroy,
    Rules.page.squil.jobOrder.location,
    Rules.page.transactions.pickup,
    Rules.page.transactions.retrieval,
    Rules.page.transactions.repickup,
    Rules.page.transactions.access,
    Rules.page.transactions.destruction,
    Rules.page.accounts.index,
    Rules.page.clients.admin,
    Rules.page.warehouse.moveLocation,
    Rules.page.reports.invoice,
    Rules.page.reports.report,
    Rules.page.settings.persons,
    Rules.page.settings.transactionTypes,
    Rules.page.settings.units,
    Rules.page.settings.migration,
  ],

  supervisor: [
    Rules.menu.squil,
    Rules.menu.transactions,
    Rules.menu.masterlist,
    Rules.menu.jobOrder,
    Rules.menu.barcodeScan,
    Rules.menu.transaction.pickup,
    Rules.menu.transaction.retrieval,
    Rules.menu.transaction.repickup,
    Rules.menu.transaction.access,
    Rules.menu.transaction.destruction,
    Rules.menu.settings,
    Rules.menu.clients,
    Rules.menu.persons,
    Rules.menu.warehouseLocation,

    Rules.page.squil.masterlist,
    Rules.page.squil.jobOrder.index,
    Rules.page.squil.jobOrder.form,
    Rules.page.squil.jobOrder.view,
    Rules.page.squil.jobOrder.receive,
    Rules.page.squil.jobOrder.pick,
    Rules.page.squil.jobOrder.destroy,
    Rules.page.squil.jobOrder.location,
    Rules.page.transactions.pickup,
    Rules.page.transactions.retrieval,
    Rules.page.transactions.repickup,
    Rules.page.transactions.access,
    Rules.page.transactions.destruction,

    Rules.page.clients.admin,
    Rules.page.warehouse.moveLocation,
    Rules.page.settings.persons,
  ],
  encoder: [
    Rules.menu.squil,
    Rules.menu.transactions,
    Rules.menu.masterlist,
    Rules.menu.jobOrder,
    Rules.menu.barcodeScan,
    Rules.menu.transaction.pickup,
    Rules.menu.transaction.retrieval,
    Rules.menu.transaction.repickup,
    Rules.menu.transaction.access,
    Rules.menu.transaction.destruction,
    Rules.menu.warehouseLocation,

    Rules.page.squil.masterlist,
    Rules.page.squil.jobOrder.index,
    Rules.page.squil.jobOrder.form,
    Rules.page.squil.jobOrder.view,
    Rules.page.squil.jobOrder.receive,
    Rules.page.squil.jobOrder.pick,
    Rules.page.squil.jobOrder.destroy,
    Rules.page.squil.jobOrder.location,
    Rules.page.transactions.pickup,
    Rules.page.transactions.retrieval,
    Rules.page.transactions.repickup,
    Rules.page.transactions.access,
    Rules.page.transactions.destruction,

    Rules.page.warehouse.moveLocation,
  ],
  picker: [
    Rules.menu.squil,
    Rules.menu.transactions,
    Rules.menu.jobOrder,
    Rules.menu.barcodeScan,
    Rules.menu.transaction.retrieval,

    Rules.page.squil.jobOrder.index,
    Rules.page.squil.jobOrder.view,
    Rules.page.squil.jobOrder.pick,
    Rules.page.squil.jobOrder.location,
    Rules.page.transactions.retrieval,
  ],

  clientadmin: [
    Rules.menu.settings,
    Rules.menu.transactions,
    Rules.menu.transaction.pickup,
    Rules.menu.transaction.retrieval,
    Rules.menu.transaction.repickup,
    Rules.menu.transaction.access,
    Rules.menu.departments,

    Rules.page.transactions.pickup,
    Rules.page.transactions.retrieval,
    Rules.page.transactions.repickup,
    Rules.page.transactions.access,
    Rules.page.clients.client,
  ],
  clientencoder: [
    Rules.menu.settings,
    Rules.menu.transactions,
    Rules.menu.transaction.pickup,
    Rules.menu.transaction.retrieval,
    Rules.menu.transaction.repickup,
    Rules.menu.transaction.access,
    Rules.menu.departments,

    Rules.page.transactions.pickup,
    Rules.page.transactions.retrieval,
    Rules.page.transactions.repickup,
    Rules.page.transactions.access,
  ]
}

export default permissions
