import { produce } from 'immer';

// -------

import ActionType from './actionTypes';

// -------

const INITIAL_STATE = {
  type: null,
  data: {},
  requesting: null,
};

// -------

const ProduceReducers = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    
    switch (action.type) {

      case ActionType.GET_REQUEST: {
        draft.requesting = true
        draft.data = {}
        break
      }
      case ActionType.GET_SUCCESS: {
        const { payload } = action
        draft.data = payload
        draft.requesting = false
        break
      }
      case ActionType.GET_FAILED: {
        draft.requesting = false
        break
      }

      default: return state
    }
  });
}

const Reducers = {
  AdminSquilTransactionViewReducers: ProduceReducers,
}

export default Reducers
