import { takeLatest, put, call } from 'redux-saga/effects';

import RouteConst from 'app/routes'

// -------

import axios from 'app/services/http/axios';
import { history } from 'app/redux';

// -------

import Actions from './actions';
import ActionTypes from './actionTypes'

import { PublicApi } from 'modules/Public'

// -------

function* signIn({ payload }) {
  const { signInSuccess, signInFailed } = Actions

  try {
    const route = PublicApi.login;
    const response = yield call(axios.post, route, { ...payload });

    yield put(signInSuccess(response));

    return history.push(RouteConst.dashboard.index);
  } catch (e) {
    return yield put(signInFailed(e));
  }
}

// -------

const watchEffects = [
  takeLatest(ActionTypes.SIGNIN_REQUEST, signIn)
]

export default watchEffects
