

const ActionTypes = {
  INITIAL_STATE: '@admin.squil.jobOrder.destroy/REQUEST/initial-state',

  GET_REQUEST: '@admin.squil.jobOrder.destroy/REQUEST/get',
  GET_SUCCESS: '@admin.squil.jobOrder.destroy/SUCCESS/get',
  GET_FAILED: '@admin.squil.jobOrder.destroy/FAILED/get',

  SAVE_REQUEST: '@admin.squil.jobOrder.destroy/REQUEST/save',
  SAVE_SUCCESS: '@admin.squil.jobOrder.destroy/SUCCESS/save',
  SAVE_FAILED: '@admin.squil.jobOrder.destroy/FAILED/save'
  
}

export default ActionTypes