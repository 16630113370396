
const ActionTypes = {
  VERIFY_REQUEST: '@public.reset-password/REQUEST/verify',
  VERIFY_SUCCESS: '@public.reset-password/SUCCESS/verify',
  VERIFY_FAILED: '@public.reset-password/FAILED/verify',

  SUBMIT_REQUEST: '@public.reset-password/REQUEST/submit',
  SUBMIT_SUCCESS: '@public.reset-password/SUCCESS/submit',
  SUBMIT_FAILED: '@public.reset-password/FAILED/submit'
}

export default ActionTypes